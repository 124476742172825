.G-accept-or-cancel-modal {
  max-width: 435px;
  width: 100%;
  padding: 8px 15px;
  display: flex;
  justify-content: center;

  flex-direction: column;

  .close {
    right: 0;
      top: 0;
    }

  .G-accept-or-cancel-content {
    width: 100%;

    h3 {
      font-size: 16px;
      line-height: 22px;
      color: #000;
      font-weight: 400;
      text-align: center;
      padding: 0 15px;
    }

    .G-cancel-print-btn-block {
      justify-content: center;
      align-items: center;
    }
  }

  .G-accept-or-cancel-btn-block {
    width: 100%;
    margin-top: 5px;
.G-confirm-execute-btn,
  .G-cancel-print-btn {
    max-width: 80px;
    width: 100%;
  }

  .G-cancel-print-btn-block {
      margin-top: 20px;
}
    }
    }