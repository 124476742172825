.G-small-wrap {
  max-width: 820px;
  width: 100%;
  .G-add-btn {
    max-width: 100px !important;
  }
  .G-button {
    p {
      font-size: 12px !important;
    }
  }
  .G-view-btn {
    max-width: 80px !important;
  }
}

.G-medium-wrap {
  max-width: 1000px;
  width: 100%;
  .G-add-btn {
    max-width: 100px !important;
  }
  .G-button {
    p {
      font-size: 12px !important;
    }
  }
  .G-view-btn {
    max-width: 80px !important;
  }
  .G-action-btn-flex {
    width: 20%;
    display: flex;
    align-items: flex-end;
    background-color: red;
  }
}
