.category-box-flex{
    width: 100%;
    display: flex;

}
.item-img-block + .item-img-block{
    margin-left: 20px;

}

.item-img-block img{
    width: 24px;
    height: 24px;
}

.category-btn{
    width: 70px;
    height: 40px;
    border :1px solid #E5EBEE;
    padding: 5px 4px;
    display: flex;
    align-items: center;
    border-radius: 5px;
    margin-top: 4px;
}
.circle-box{
    width: 35px;
    height: 35px;
    border-radius: 50%;
    background-color: #eee;
    display: flex;
    justify-content: center;
    align-items: center;
}

.arrow{
    display: block;
    width: 16px;
    height: 16px;
}
.category-style{

}
.category-title{
    font-size: 14px;
    font-weight: 500;
}



.show-box-arrow > img{
width: 15px;
height: 15px;
margin-left: 8px;
}

.show-dropdown-box{
    width: 140px;
    border: 1px solid #E5EBEE;
    margin-top: 8px;
    border-radius: 5px;
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    padding: 2px;
  
       max-height: 140px;
        overflow-y: auto;

        &::-webkit-scrollbar {
          width: 7px;
          height: 8px;
          background: #f5f5f5;
          border-radius: 100px;
        }

        &::-webkit-scrollbar-thumb {
          background-color: #858a95;
          width: 8px;
          -webkit-border-radius: 10px;
          border-radius: 10px;
          cursor: pointer;
        }
}

.color-box, .img-box{
    width: 35px;
    height: 35px;
    border-radius: 50%;
    display: block;
}
.img-box{
    background-color: red;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #8BA4B5;
}

.react-colorful{
    margin-top: 10px;
    //height: 80px !important;
}