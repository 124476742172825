.G-radio-button {
  display: flex;
  align-items: center;

  > label {
    font-size: 12px;
    color: black;
  }

  > input {
    cursor: pointer;
  }
  input[type='radio'] {
    accent-color: #750015;
  }
}
