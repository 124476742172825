.L-calculation-data-action-wrap-flex {
  display: flex;
  justify-content: space-between;
  align-items: center;
  .L-calculation-box {
    max-width: 350px;
    width: 100%;
    margin-left: 10px;
    &:first-child {
      margin-left: 0;
    }
    &:nth-child(2) {
      max-width: 210px;
      width: 100%;
    }
    &:nth-child(5) {
      max-width: 200px;
      width: 100%;
    }

    &:nth-child(6) {
      max-width: 252px;
      width: 100%;
    }
  }
  .calendar-wrap {
    max-width: 220px;
    width: 100%;
    padding: 0;
  }
  .calendar-box {
    &:last-child {
      margin-left: 10px;
    }
  }
}
.L-calculation-filed {
  width: 90%;

  display: flex;
  justify-content: space-between;
  align-items: center;
}
.L-calculation-btn {
  width: 10%;

  display: flex;
  justify-content: flex-end;
  align-items: center;
}

.L-calculation-data-table {
  .G-table-body-cell {
    &:nth-child(9),
    &:nth-child(10) {
      justify-content: end;
    }
  }
}
.L-calculation-data-block {
  .vacation-total-label {
    max-width: 30px;
    width: 100%;
  }
  .vacation-total-value {
    max-width: 180px;
  }
}
