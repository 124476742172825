

.G-title-p {
  font-size: 12px;
  line-height: 24px;
  color: #1e1515;
  font-weight: 400;
}

.G-input-border-style {
  border: 1px solid #8b8b8b !important;
  padding-left: 5px !important;
  border-radius: 4px !important;

  &:hover,
  &:focus {
    border: 1px solid #571845 !important;
  }
}

.G-search-btn {
  max-width: 95px;
  width: 100%;
  margin: 35px auto 0;
}

.G-save-cancel-btn-width {
  width: 100%;
}

.G-form-title {
  text-align: center;
  font-size: 16px;
  font-weight: 300;
  margin-bottom: 10px;
}

.L-analytical-accounts-balances-block{
  max-width: 1000px !important;
  width: 100%;
  .L-analytical-accounts-balances-table{
      .G-table-body-cell {

    &:nth-child(3),

    &:nth-child(4) {
      justify-content: flex-end;
    }
  }
  }
}

