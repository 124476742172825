.L-entry-order-operations-form {
  max-width: 1000px;
  width: 100%;
}

.L-entry-order-delete-btn {
  max-width: 120px;
  width: 100%;
  margin-top: 12px;
}
