.L-log-out {
  width: 32px;
  height: 32px;
  cursor: pointer;
  margin-left: 15px;

  > img {
    display: flex;
    width: 100%;
    height: 100%;
  }
}
