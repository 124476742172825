.L-year-and-mont-block {
  .year-view {
    &:last-child {
      margin-left: 15px;
    }
  }
  .year-view {
    .css-qc6sy-singleValue {
      font-size: 14px;
    }
  }

  .mount-view{
    .css-qc6sy-singleValue{
         font-size: 14px;
    }
  }
}

.custom-event {
  padding: 5px 3px;
  border-radius: 3px;
  background-color: black;
  color: white;
  overflow: hidden;
}

.fc-h-event {
  border: none;
}

