.tables-form-block {
  max-width: 400px;
  width: 100%;
  .tables-inp-wrap {
    margin-top: 15px;
    &:nth-child(1) {
      max-width: 80px;
    }
    &:nth-child(4) {
      .G-s-input {
        max-width: 80px;
      }
    }
  }
}
.tables-radio-btn-flex {
  display: flex;

  .G-radio-button {
    &:last-child {
      margin-left: 15px;
    }
  }
}
