.L-salary-calculation-wrap {
  max-width: 300px;
  width: 100%;
  height: 250px;
  border: 1px solid #babec5;
  box-shadow: 0 19px 38px rgba(0, 0, 0, 0.3), 0 15px 12px rgba(0, 0, 0, 0.22);
  padding: 20px;
  border-radius: 5px;
  h3 {
    text-align: center;
    font-size: 12px;
    font-weight: 400;
  }
}

.G-salary-calculation-form {
  display: flex;
  align-items: flex-end;

  .L-salary-calculation-filed {
    width: 90%;
    .G-select-control {
      max-width: 220px;
      width: 100%;
    }
  }
  .G-add-btn {
    max-width: 120px;
    width: 100%;
    margin-left: 10px;
  }
  .L-salary-calculation-btn-block {
    display: flex;
    align-items: flex-end;
    justify-content: flex-end;
  }
}
.L-salary-calculation-table {
  .G-table-body-cell {
    &:nth-child(7),
    &:nth-child(8),
    &:nth-child(9),
    &:nth-child(10) {
      justify-content: flex-end;
    }
  }
}

.salary-calculation-total {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  margin-top: 10px;
  h4 {
    font-size: 14px;
    font-weight: 100;
    margin-right: 15px;
  }
  p {
    max-width: 75px;
    width: 100%;
    font-size: 13px;
    text-align: end;
  }
  p + p {
    max-width: 165px;
    width: 100%;
    text-align: end;
    font-size: 13px;

    margin: 0 5px;
    &:last-child {
      margin-right: 85px;
    }
  }
}
