.G-numeric-format-filed {
  width: 100%;
  input {
    width: 100%;
    height: 34px;
    background: #fff;
    border-bottom-left-radius: 4px;
    border: 1px solid #737373;
    border-top: none;
    border-right: none;
    transition: 0.3s;
    padding-left: 5px;
    font-size: 13px !important;
  }

  .G-title-p {
    padding-left: 5px;
  }
}
.input-has-border {
  input {
    border: 1px solid #8b8b8b !important;
    padding-left: 5px !important;
    border-radius: 4px !important;

    &:hover,
    &:focus {
      border: 1px solid #3d3b3b !important;
    }
  }
}
