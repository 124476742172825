.menu-bar,
.active-menu {
  width: 4%;
  height: 100vh;
  position: fixed;
  left: 0;
  top: 0;
  background: #076a2a;
  transition: 0.3s;
  z-index: 5;
}

.active-menu {
  width: 250px;

  .menu-item {
    justify-content: unset;
  }
}

.menu-item {
  width: 100%;

  display: flex;
  justify-content: center;

  transition: 0.3s;
}

.hidden {
  display: none;
}

.menu-item {
  img {
    max-width: 40px;
    height: 40px;
  }
}

.menu-item a span {
  color: white;
  margin-left: 10px;
}

.open-close-icon {
  position: absolute;
  right: -20px;
  top: 60px;
  height: 40px;
  width: 40px;
  border-radius: 50%;
  background: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;

  svg {
    width: 22px;
    height: 22px;
    fill: white;
  }
}

.rotate-icon {
  svg {
    transform: rotate(180deg);
  }
}

.personal-budget {
  margin-left: 33%;
  padding-top: 25px;

  span {
    font-size: 19px;
    color: white;
    font-weight: bold;
  }
}
.menu-list ul a {
  position: relative;
}
.menu-is-active {
  width: 20px;
  height: 20px;
  position: absolute;
  right: -20px;
  top: auto;
  background-image: url('../../icons/openClose.svg');
}

.router-bar {
  margin-top: 70px;
  .menu-item {
    margin-top: 40px;
  }
}
