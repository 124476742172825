.L-day-not-block {
  border: 1px solid rgb(158, 155, 155);
  margin-top: 20px;
  padding: 10px;
  box-shadow: 0 19px 38px rgba(0, 0, 0, 0.3), 0 15px 12px rgba(0, 0, 0, 0.22);
  border-radius: 5px;
  max-height: 460px;
  overflow-x: auto;
  .calendar-title {
    text-align: center;
  }
}
.L-day-not-item {
  margin-top: 12px;
  h4 {
    font-size: 14px;
    font-weight: bold;
  }
  span {
    display: block;
    margin-left: 10px;
    font-size: 12px;
  }
}
