.L-operation-by-rows-modal{
    max-width: 1200px;
    width: 100%;
    .L-operation-by-rows-table{
.G-table-body-cell {
    
    &:nth-child(4),
    &:nth-child(5) ,  
     &:nth-child(6),  &:nth-child(7),   &:nth-child(8),
     &:nth-child(9),&:nth-child(10)
     {
      justify-content: flex-end;
    }
  }
    }
}