



.spinner::after {
  content: '';
  box-sizing: border-box;
  width: 20px;
  height: 20px;
  position: absolute;
  top: calc(50% - 9px);
  left: calc(50% - 9px);
  border-radius: 50%;
}



.spinner.material::after {
  border-top: 4px solid rgba(255, 255, 255, 1.0);
  border-left: 4px solid rgba(255, 255, 255, 1.0);
  border-bottom: 4px solid rgba(255, 255, 255, 1.0);
  border-right: 4px solid rgba(255, 255, 255, 0.0);
  animation: spinner .6s linear infinite;
}



@keyframes spinner {
  to {transform: rotate(360deg);}
}
