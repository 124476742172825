@import '../../Assets/Scss/mixin.scss';

.G-table-block {
  width: 100%;
  border: 1px solid #737373;
  margin-top: 8px;

  .G-table-header {
    background: #742f47;

    .G-table-header-row {
      display: flex;
      align-items: center;
      height: 32px;

      .G-table-header-cell {
        width: 100%;
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 13px;
        color: #fff;
        font-weight: 400;
        line-height: 24px;
        border-left: 1px solid #d6d6d6;
        position: relative;

        &:first-child {
          border-left: none;
        }
      }
    }
  }

  .G-table-body {
    .G-table-body-row {
      display: flex;
      align-items: center;
      height: 32px;
      background: #eeee;
      transition: 0.3s;
      // &:last-child {
      //   .P-toolbar-list {
      //     top: auto;
      //     bottom: 0;
      //   }
      // }
      &:nth-child(even) {
        background: #ffffff;
      }

      &:hover {
        background-color: rgba(34, 9, 27, 0.2);
      }

      .G-table-body-cell {
        width: 100%;
        height: 100%;
        display: flex;
        align-items: center;
        border-left: 1px solid #d6d6d6;
        font-size: 12px;
        line-height: 16px;
        color: black;
        padding: 0 5px;
        white-space: nowrap;

        &:first-child {
          border-left: none;
        }
      }
    }

    .G-table-icon {
      display: block;
      margin: 0 auto;
      cursor: pointer;

      svg {
        width: 16px;
        height: 16px;
      }
    }

    .G-tables-input {
      width: 100%;
      height: 32px;
      background: transparent;
    }
  }

  .G-list-input {
    width: 100%;
    height: 32px;
    background: transparent;
    padding-left: 10px;
  }

  .G-checkbox {
    display: flex;
    align-items: center;
    justify-content: center;
  }
}

.G-table-has-scroll {
  .G-table-header-cell {
    padding: 0 7px;
  }

  .G-table-body {
    @include tableScrollBar;
    max-height: calc(32 * 15px);
  }
}

.G-active-table {
  background-color: rgba(34, 9, 27, 0.2) !important;
}

.G-new-data {
  background: green !important;
}

.isEdit {
  width: 18px;
  height: 18x;
  margin-left: 10px;
}
