.L-one-window-logo {
  display: flex;
  align-items: center;
  justify-content: center;
  
.isActiveTab{
    &::before{
        bottom: -8px;
    }
}
  .L-logo {
    max-width: 30px;
    width: 100%;
    cursor: pointer;
    img {
      width: 100%;
      height: 30px;
    }
  }
}
