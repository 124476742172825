.elp-modal {
  max-width: 630px;
  width: 100%;
  border-radius: 15px;
}

.helper-p {
  font-size: 14px;
  line-height: 20px;
  text-align: left;
  margin-top: 10px;
}

.helper-content {
  padding-top: 20px;
}


.helper-message{
  font-size: 13px;
}