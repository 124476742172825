.G-calendar-wrapper {
  max-width: 210px;
  width: 100%;
  .G-calendar-box-flex {
    .G-calendar-box {
      &:first-child {
        margin-right: 15px;
      }
    }
  }
}
