.L-employees-dashboard-block {
  margin-top: 20px;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  max-width: 1281px;
  width: 100%;
}
.G-salary-title {
  font-size: 16px;
  line-height: 21px;
  color: #0e2048;
  font-weight: 700;
  margin: 10px 0;
}
.L-salary-display-container {
  max-width: 500px;
  width: 100%;
  border: 1px solid rgba(0, 0, 0, 0.459);
  padding: 10px;
  border-radius: 5px;
  box-shadow: 0 19px 38px rgba(0, 0, 0, 0.3), 0 15px 12px rgba(0, 0, 0, 0.22);
  p {
    font-size: 12px;
  }
}
.L-salaryCalc-tab {
  display: flex;
  justify-content: space-between;

  button {
    width: 50%;
    height: 32px;

    font-size: 12px;
  }
}
.L-salary-display-input {
  max-width: 260px;
  width: 100%;
  display: flex;
  margin: 0 auto;
  .G-numeric-format-filed {
    border-left: 1px solid #ddd;
  }
  .G-numeric-format-filed,
  input {
    border: transparent;
    background: transparent;
    color: #3c439c;
    font-weight: bold;
    font-size: 14px !important;
    text-align: center;
    &::placeholder {
      font-size: 12px;
      font-weight: 100;
      color: #000;
      text-align: center;
    }
  }
}

.L-salary-display-item {
  display: flex;

  border: 1px solid #ddd;
  border-radius: 12px;

  margin-top: 10px;
  p {
    width: 50%;
    padding: 10px 20px;
  }
  p + p {
    margin-left: 15px;
    border-left: 1px solid #ddd;
    height: 100%;
  }
}
