.close-wrap {
  position: absolute;
  right: 9px;
  top: 8px;
  cursor: pointer;
  z-index: 9999;
}

.close {
  position: relative;
  width: 20px;
  height: 20px;
}

.close:before,
.close:after {
  position: absolute;

  content: ' ';
  height: 20px;
  width: 2px;
  background-color: black;
}

.close:before {
  transform: rotate(45deg);
  left: 10px;
}

.close:after {
  transform: rotate(-45deg);
  right: 8px;
}
