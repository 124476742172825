.G-search-form-flex {
  display: flex;
  align-items: center;
}

.G-salary-component {
  .scrollable {
    max-height: unset;
    overflow-y: unset;
  }
  .G-pagination-block {
    background: #a7adf7;

    .paginationBttns {
      &:hover {
        color: #31389c;
      }
    }
  }

  .G-button {
    background: #3c439c;
    border: 1px solid #3c439c;

    &:hover {
      box-shadow: inset 0px 4px 4px rgb(0 0 0 / 25%);
      background: #31389c;
    }
  }
  .move {
    background: #777dcb;
  }
  .G-search-bg {
    background: #a7adf7;
  }
  .G-table-header {
    background: #5e66c5;
  }

  .P-toolbar-list {
    .G-toolbar-block {
      li {
        &:hover {
          background: #31389c;
        }
      }
    }
  }
  .G-checkbox-wrapper {
    input[type='checkbox'] {
      accent-color: #3c439c;
    }
  }

  input[type='radio'] {
    accent-color: #3c439c;
  }
}

.input-container {
  position: relative;
}
.G-confirm-btn-wrap {
  max-width: 150px;
  width: 100%;
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
  margin-top: 15px;
}

.G-up-arrow-block {
  display: flex;
  align-items: center;
  margin-top: 15px;
  margin-left: 5px;
  border-top: 1px solid #3c439c;
  height: 32px;
  img {
    max-width: 18px;
    width: 100%;
    height: 20px;
    display: flex;

    transform: rotate(178deg);
    cursor: pointer;
  }
  p {
    font-size: 12px;
    line-height: 14px;
    color: black;
    margin-left: 5px;
  }
}

.G-confirm-icon {
  max-width: 80px;
  width: 100%;
  max-height: 23px;
}
