.G-one-window-view-footer {
  display: flex;
  align-items: center;
  justify-content: center;

  padding: 20px 0;
  background-color: #C5C3C3;

margin-top: 10px;
  p {
    color: #4a4949;
    margin-top: 0 !important;
  }
}


.L-social-icon {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 25px;
  height: 25px;
  border-radius: 50%;
  background-color: #949494;
  margin-left: 8px;
  margin-top: 0 !important;

  >a {
    >img {
      width: 16px;
      height: 16px;
      display: flex;
    }
  }
}