
.modal-block {
    max-width: 650px;
    width: 100%;
    background:white;
    border: 1px solid #dfc3c3;
    border-radius: 4px;
   position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
  
    z-index: 999;
    padding: 10px 15px;
    box-shadow: 0 19px 38px rgba(0, 0, 0, 0.3), 0 15px 12px rgba(0, 0, 0, 0.22);
 
}


// .move-icon{
//     width: 30px;
//     height: 30px;
//     background: #a98ca1;
//     cursor: pointer;
//     border-radius: 50%;

//     background-size: cover;
//     background-repeat: no-repeat;
  
// }

// .modal-body{
// margin-top: 15px;
// }