@import './mixin.scss';

form {
  width: 100%;
}

.G-add-btn {
  @include largeBtn;
}

.G-description-input {
  max-width: 400px;
  width: 100%;
}

.G-view-btn {
  max-width: 120px;
  width: 100%;
}

.G-partner-type-box {
  max-width: 300px;
  width: 100%;
}

.G-modal-view-date-style {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 40px;
  font-size: 14px;
  font-weight: 100;
  padding-right: 45px;
}

.G-modal-view-table {
  max-width: 1400px !important;
  width: 100%;
}

.L-purchase-view-flex {
  .G-add-btn {
    margin-top: 30px;
  }
}

.G-docNum-input-not-border {
  max-width: 150px;
  width: 100%;
}

.G-purchase-rep-person-input {
  max-width: 250px;
  width: 100%;
}

.G-register-document-btn {
  max-width: 225px !important;
  width: 100%;
}

.G-time-partner-flex {
  .G-partner-wrapper {
    margin-left: 15px;
  }
}

.G-modal-block-title {
  h3 {
    font-size: 15px;
    line-height: 24px;
    color: black;
    font-weight: 400;
    text-align: center;
    word-break: break-word;
  }
}

.G-form-data-picker {
  width: 120px;
}

.h3 {
  font-size: 18px;
  line-height: 24px;
  color: black;
  font-weight: 400;
}

.G-taxCode-input-filed {
  max-width: 150px;
  width: 100%;
}

.title-h3 {
  width: 100%;
  margin-top: 15px;
  font-size: 16px;
  font-weight: 200;
  min-height: 22px;
}

.G-small-btn {
  max-width: 125px;
  width: 100%;
}

.G-form-modal {
  max-width: 500px !important;
  width: 100%;

  .G-form-input-wrapper {
    position: relative;
    width: 100%;
    margin-top: 10px;
  }
}

.G-form-select-wrapper {
  width: 100%;
  position: relative;
}

.G-exchange-input {
  max-width: 100px;
  width: 100%;
  position: relative;
}

.G-docNum-input {
  max-width: 145px;
  width: 100%;
}

.G-modal-partner-name {
  h3 {
    font-size: 18px;
    font-weight: 400;
  }
}

.G-delete-span {
  margin: 0 auto;
  cursor: pointer;
}

.G-register-btn {
  max-width: 100px;
  width: 100%;
}

.G-normal-btn {
  max-width: 120px;
  width: 100%;
}

.leLeave-itLater-btn {
  margin-right: 300px;
}

.L-number-page-box {
  max-width: 250px;
  width: 100%;
  border: 1px solid #eee;
  padding: 15px;
  margin-top: 15px;

  .G-register-btn {
    margin-top: 15px;
  }
}

.G-comment-filed {
  margin-top: 10px;
}

.G-draggable-wrapper {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.G-notes-filed {
  .G-title-p {
    margin-left: 5px;
  }

  textarea {
    width: 100%;
    height: 34px;
    background: #fff;
    border-bottom-left-radius: 4px;
    border: 1px solid #737373;
    border-top: none;
    border-right: none;
    transition: 0.3s;
    padding-left: 5px;
    padding-top: 5px;
  }
}

.L-currency-box {
  padding-right: 10px;

  span {
    display: block;
    margin-top: 10px;
    font-size: 14px;
  }
}

.G-accounting-logo-wrap {
  display: flex;
  align-items: center;
  padding-top: 10px;
  padding-left: 40px;
  .L-logo-box {
    width: 50px;
    height: 50px;
    display: flex;

    img {
      width: 100%;
      height: 100%;
    }
  }

  h3 {
    font-size: 18px;
    margin-left: 5px;
    font-weight: bold;
  }
}
.G-account-reevaluation-icon {
  display: block;
  margin: 0 auto;
  cursor: pointer;

  svg {
    width: 20px;
    height: 20px;
  }
}

.L-excel-icon {
  max-width: 30px;
  width: 100%;
  height: 30px;
  cursor: pointer;
  margin-left: 15px;
  img {
    width: 100%;
    height: 100%;
  }
}

.L-document-entries-block {
  max-width: 1300px;
  width: 100%;
}

.G-vive-print-btn {
  max-width: 160px;
  width: 100%;
}

.G-income-tax-btn {
  max-width: 160px;
  width: 100%;
}

.L-currencies-table,
.L-organization-bank-table {
  margin-top: 80px;
}
.G-amountArm-Legislation {
  font-size: 10px;
  color: red;
  padding: 2px 0;
}

.G-print-excel-block {
  max-width: 200px;
  width: 100%;
  display: flex;
  align-items: center;
}
.G-excel-print-btn-flex {
  display: flex;
  align-items: center;
  .G-cancel-print-btn-block {
    margin-top: 0;
  }
}

.L-history-taxation-system {
  max-width: 740px;
  width: 100%;
}
