.G-textarea {
  width: max-content;
  border-bottom-left-radius: 4px;
  border: 1px solid #154632;
  border-top: none;
  border-right: none;
  padding: 5px 10px;
  min-height: 28px;
  max-height: 100px;
  resize: vertical;
  font-size: 14px;
}
