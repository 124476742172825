.L-purchase-view-block {
  max-width: 1200px;
  width: 100%;
  .G-income-tax-btn{
    margin-top: 15px
  };
}

.L-purchase-act-view-block {
  margin-top: 15px;

  .G-docNum-input-not-border {
    margin: 0 15px;
  }
}

.L-purchase-select-wrapper {
  margin: 15px 0;

  .G-account-wrapper {
    max-width: 400px;
    width: 100%;
  }
}
.L-from-whom-form{
    max-width: 400px;
    width: 100%;
}

.L-purchase-product-modal {
  max-width: 500px;
  width: 100%;

  .L-purchase-product-form {
    width: 100%;

    .L-product-select-wrapper {
      max-width: 100%;
      margin-bottom: 15px;
    }
    .L-product-wrap {
      width: 100%;

      .L-product-select-wrapper {
        width: 90%;
      }

      .G-add-icon {
        margin-top: 24px;
      }
    }

    .L-purchase-product-input {
      width: 50%;
      margin-bottom: 15px;
    }
  }
}

.L-purchase-act-table {
  .G-table-body-cell {
    &:nth-child(5) {
      justify-content: flex-end;
    }
  }
}

//start L-income-tax-modal style

.L-income-tax-modal {
  max-width: 450px;
  width: 100%;

  .G-account-wrapper {
    max-width: 100%;
  }

  .L-income-tax-filed {
    width: 100%;
    position: relative;
    margin-top: 10px;

    &:nth-child(3) {
      width: 50%;
    }
  }
}

.G-purchase-act-partner-flex {
  width: 100%;

  .G-add-icon {
    margin-top: 25px;
    background: white;
  }
}

.L-purchase-act-operations-block{
  max-width: 1300px;
  width: 100%;

  .L-purchase-act-operation-table {
    .G-table-body-cell {
    

     
      &:nth-child(7),
      &:nth-child(8) {
        justify-content: flex-end;
      }
    }
  }
}

.L-purchase-act-product-table {
  .G-table-body-cell {
    &:nth-child(4),
    &:nth-child(5) {
      justify-content: flex-end;
    }
  }
}
