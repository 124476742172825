@import '../../Assets/Scss/mixin.scss';

.L-summary-table {
  .G-table-body-cell {
    &:nth-child(2) {
      white-space: nowrap;
      overflow: hidden;
    }
  }
}

.L-circulation-newsletter {
  .G-currency-wrapper {
    margin-left: 15px;
  }
}
.L-circulation-newsletter-cur {
  margin-left: 108px;

  align-items: center;
  h3 {
    font-size: 14px;
  }
  p {
    margin-left: 10px;

    font-size: 12px;
  }
}

.L-circulation-system {

    .G-table-body-cell {
          &:nth-child(2){
overflow: hidden;
          }
      &:nth-child(3),
      &:nth-child(4),
      &:nth-child(5),
      &:nth-child(6),
      &:nth-child(7),
      &:nth-child(8) {
        justify-content: flex-end;
      }
    }
  
}
