.L-authenticated-filter {
  display: flex;
  .G-select-control {
    max-width: 250px;
    width: 100%;
  }
  .G-material-value-input-wrap {
    max-width: 190px;
    width: 100%;
    margin-left: 10px;
  }
}

.L-authenticated-header {
  display: flex;
  align-items: center;

  h3 {
    font-size: 14px;
    font-weight: 400;
  }
  .G-material-value-input-wrap {
    max-width: 180px;
    width: 100%;
    margin-left: 15px;
  }
}

.L-identification-code-wrap {
  display: flex;
  align-items: flex-start;
  .G-add-icon {
    margin-top: 23px;
  }
}

// .L-authenticated-form {
//   height: 600px;
//   overflow-x: auto;
// }
