.G-salary-calender {
  max-width: 150px;
  width: 100%;
  display: flex;
  flex-direction: column;
  .input-group-prepend {
    border: 1px solid #8b8b8b;
    input {
      width: 80px;
      height: 32px;
    }
  }
}

.react-datepicker-wrapper {
  .form-control {
    width: 100px;
    height: 34px;
    text-align: center;
    border: 1px solid #737373;
    border-radius: 4px;
    font-size: 12px;
    line-height: 16px;
    color: black;
    transition: 0.3s;
    &:hover {
      cursor: pointer;
      box-shadow: inset 0px 4px 4px rgb(0 0 0 / 25%);
    }
  }
}
.G-input-group-calender {
  .form-control {
    width: 100px;
    height: 32px;
    border: 1px solid #8b8b8b;
  }
  .select-form-control {
    width: 140px;
    height: 32px;
    border: 1px solid #8b8b8b;
    option {
      font-size: 14px;
      text-transform: capitalize;
    }
  }
}
.select-control {
  width: 100px;
  height: 32px;
  border: 1px solid #ced4da;
}

.input-group-prepend,
.input-group-append {
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid #6c757d;

  .btn {
    width: 21px;
    height: 32px;
    color: #6c757d;
    font-size: 15px;
  }
}

.G-calendar-box {
  width: 82px;
  &:last-child {
    margin-left: 10px;
  }
}
