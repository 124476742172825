.L-general-benefit-calculation-block {
  display: flex;
  justify-content: center;
  form {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    margin: -10px;
  }
  .L-general-benefit-filed,
  .G-checkbox-wrapper {
    width: 50%;
    padding: 10px;
    &:nth-child(4),
    &:nth-child(5),
    &:nth-child(6),
    &:nth-child(7),
    &:nth-child(8),
    &:nth-child(9) {
      .G-s-input {
        max-width: 150px;
        width: 100%;
      }
    }
    .G-numeric-format-filed {
      input {
        max-width: 150px;
        width: 100%;
      }
    }
  }
}
