.L-partner-form {
  max-width: 550px;
  width: 100%;

  .L-partner-form-content {
    width: 100%;

    .L-partner-wrapper {
      width: 100%;
      position: relative;
      margin-top: 10px;
    }
    .G-partner-type-wrapper {
      max-width: 100%;
      width: 100%;
    }
  }
}

.L-partner-balance-modal {
  max-width: 1200px;
  width: 100%;
}

.L-partner-bank-modal {
  max-width: 1160px;
  width: 100%;
.scrollable{
overflow-y: unset;
}
  .G-header-block {
    .G-header-company-name {
      font-size: 12px !important;
    }
    .G-page-title {
      font-weight: 400;
      font-size: 16px !important;
    }
  }
}

.L-partner-table {
  .G-table-body-cell {
    &:nth-child(3) {
      justify-content: center;
    }
  }
}

.L-partner-balance-modal-content{

}
.L-partner-balance-table {
  width: 100%;

  .G-table-body-cell {
    &:nth-child(2),
    &:nth-child(4),
    &:nth-child(5),
    &:nth-child(6),
    &:nth-child(7) {
      justify-content: flex-end;
    }
  }
}

.L-partner-bank-form {
  .G-form-select-wrapper {
    margin-top: 10px;
  }

  .L-partner-bank-table {
    .G-table-body-cell {
      &:nth-child(1),
      &:nth-child(4) {
        justify-content: flex-end;
      }
    }
  }
}

.L-partner-bank-type {
  width: 55%;
  display: flex;
  align-items: center;
  padding: 8px 5px;
  border: 1px solid #737373;
  border-radius: 4px;
  margin-top: 10px;

  .G-radio-button {
    &:last-child {
      margin-left: 15px;
    }
  }
}

.L-is-bank-block {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  margin-top: 10px;
  h3 {
    font-size: 14px;
    font-weight: 400;
    word-wrap: break-word;
  }
  span {
    font-size: 14px;
    display: block;
    cursor: pointer;
    margin-top: 5px;
    padding: 5px 8px;
    border: 1px solid #571845;
    border-radius: 5px;
    font-weight: 600;
    color: white;
    background: #571845;
  }
  span + span {
    margin-left: 35px;
  }
}

.L-init-balance-form-content {
  .G-modal-block-title {
    h3 {
      font-size: 14px;
    }
  }
}

.strong {
  word-wrap: break-word;

  font-weight: bold;
  margin-right: 10px;
}

.L-physical-person-flex {
  justify-content: space-between;
  margin: 0 -6px;
  .L-partner-wrapper{

    padding: 0 6px;
  }
}