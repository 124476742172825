.L-employee-position-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 20px;
  .L-employee-position-i {
    display: flex;
    align-items: center;
    h4 {
      font-weight: 100;
    }
    span {
      display: block;
      margin-left: 5px;
      font-size: 16px;
    }
  }
}
.L-confirm-employee-position {
  .vacation-total-section {
    .vacation-total-value {
      max-width: 150px;
    }
  }
}
