.G-total-wrap{

    display: flex;
    justify-content: flex-end;
padding: 10px 5px;
    p{
      
        font-size: 12px;
        font-weight: 500;
        color: #000;
    }
}