.L-tab-buttons {
  .tab-btn-isActive {
    background: #154632;
    border: 1px solid #154632;
  }
}

.L-material-values-name {
  display: flex;
  flex-direction: column;
  margin-top: 10px;
}

.L-material-values-names-form {
  max-width: 500px;
  width: 100%;
  height: 520px;

  .L-material-value-name-filed {
    margin-top: 10px;

    &:first-child {
      max-width: 140px;
      width: 100%;
    }
  }
}

.L-mat-name-inp-wrap {
  width: 50%;

  &:last-child {
    margin-left: 15px;
  }
}
.L-material-val-name-filter-block {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.L-material-val-name-filter-panel {
  width: 45%;

  display: flex;
  align-items: center;
  .G-select-control {
    max-width: 270px;
    width: 100%;
  }
  .G-search-input-wrapper {
    margin-left: 15px;
  }
}
.G-dropdown-flex-block {
  display: flex;
  align-items: center;
  width: 100%;
  .G-add-icon {
    margin-top: 26px;
  }
  .G-select-control {
    width: 90%;
  }
}
