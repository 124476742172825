.L-progress-main-block {
  margin-bottom: 10px;

  .L-progress {
    display: flex;
    height: 1rem;
    overflow: hidden;
    line-height: 0;
    font-size: 0.75rem;
    background-color: #e9ecef;
    border-radius: 0.25rem;

    .L-progress-bar {
      display: flex;
      flex-direction: column;
      justify-content: center;
      overflow: hidden;
      color: #fff;
      text-align: center;
      white-space: nowrap;
      background-color: #007bff;
      transition: width 0.6s ease;
    }
  }

  p {
    text-align: right !important;
    color: rgb(130, 130, 130);
    font-size: 14px;
    margin-top: 5px;
  }
}

.L-data-sent-message {
  max-width: 450px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;

  > h2 {
    font-size: 18px;
    font-family: Verdana, Geneva, Tahoma, sans-serif;
    text-align: center;
    line-height: 25px;
  }

  .L-go-back-btn {
    max-width: 125px;
    width: 100%;
    margin-top: 15px;
  }
}

.L-reg-box {
  display: flex;

  .G-asterisk {
    padding: 0;
    margin-right: 5px;
  }
}

.L-tel-filed {
  max-width: 320px;
  width: 100%;
}
.L-register-form {
  .G-error-message {
    color: black;
  }
}
