.G-cancel-print-btn-block {
  width: 100%;
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
  margin-top: 30px;
  .G-btn-style {
    max-width: 120px;
    width: 100%;
    &:last-child {
      margin-left: 18px;
    }
  }
}
