.L-header-icon-wrap {
  max-width: 64px;
  width: 100%;
  height: 59px;
  cursor: pointer;
  position: relative;
  transition: 0.4s;
  display: flex;
  align-items: center;
  &:nth-child(2) {
    max-width: 107px;
  }
  &:nth-child(3) {
    max-width: 64px;
    img {
      max-width: 36px;
    }
  }
  &:hover {
    .L-header-title {
      opacity: 1;
    }
    .L-header-img {
      opacity: 0;
    }
  }

  &::before {
    content: '';
    position: absolute;
    width: 0;
    height: 3px;
    left: 0;
    bottom: -1px;
    background-color: #22b61f;
    border-radius: 2px;
    transition: 0.3s;
  }

  &:hover {
    &::before {
      width: 100%;
    }
  }
}

.L-header-title {
  font-size: 14px;
  transition: 0.3s;
  position: absolute;
  left: 0;
  opacity: 0;
}

.L-header-img {
  width: 100%;
  height: 100%;
  position: relative;
  object-fit: contain;
  object-position: bottom;
  transition: 0.3s;
}

.isActiveTab {
  &::before {
    width: 100%;
  }
}

.isActiveTab {
  position: relative;

  &::before {
    content: '';
    position: absolute;
    width: 100%;
    height: 3px;
    left: 0;
    bottom: -1px;
    background-color: #22b61f;
    border-radius: 2px;
    transition: 0.3s;
  }
}
