@import './mixin.scss';
$MarginBottom: 20px;

.G-login-register-main-form {
  max-width: 400px;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  margin: 0 auto;
  padding: 20px;
  background-color: rgb(255, 255, 255);
  border: 1px solid rgb(186, 190, 197);
  box-shadow: 0 19px 38px rgba(0, 0, 0, 0.3), 0 15px 12px rgba(0, 0, 0, 0.22);

  .G-log-reg-form-header {
    display: flex;
    align-items: center;

    h3 {
      font-size: 17px;
      margin-left: 20px;
    }
  }

  .G-log-reg-container {
    max-width: 360px;
    width: 100%;
    margin-top: 20px;
    .G-log-reg-input-wrapper {
      width: 100%;
      margin-bottom: 35px;
      position: relative;

      input {
        padding-left: 30px !important;
      }
    }

    .G-log-reg-btn {
      max-width: 120px;
      width: 100%;
      margin: 40px auto 5px auto;
      button {
        background-color: #750015;
      }
    }
  }

  .L-log-reg-remember-password {
    margin-top: -16px;

    button {
      font-size: 12px;
      line-height: 14px;
      color: #000;
    }
  }

  .L-do-not-account {
    margin-top: 20px;
    p {
      font-weight: 700;
      font-size: 13px;
      line-height: 16px;
      color: #333;
    }

    strong {
      margin-left: 5px;
      color: #750015;
      position: relative;
      font-weight: 700;
      font-size: 13px;
      line-height: 16px;
      cursor: pointer;
    }
  }
}

// stex perqa mi hat globalacnem
.L-input-icon {
  display: flex;
  position: absolute;
  left: 10px;
  top: 50%;
  transform: translateY(-50%);

  > svg {
    width: 14px;
    height: 14px;
  }
}

.L-toggle-icon {
  display: flex;
  position: absolute;
  right: 10px;
  top: 50%;
  transform: translateY(-50%);

  > svg {
    width: 14px;
    height: 14px;
  }
}

.privacy-policy-block {
  margin-top: 20px;
  p {
    font-size: 11px;
    color: black;
    a {
      color: black;
      border-bottom: 1px solid black;
      font-size: 11px;
    }
  }

  .G-checkbox-wrapper {
    margin-top: 15px;
  }
  .G-checkbox-wrapper {
    > .label {
      font-size: 11px;
    }
    input[type='checkbox'] {
      width: 20px;
      height: 20px;
    }
  }
}
