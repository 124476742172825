.G-right-panel {
    width: 96%;
    height: 100vh;
    position: relative;
}

.G-active-side {
    width: 80%;

    .G-right-panel {
        width: 0;
    }
}
