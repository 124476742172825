@import '../../Assets/Scss/mixin.scss';

.G-header-block {
  .G-add-btn {
    @include largeBtn;
  }
}

.G-header-bot-area {
  margin-top: 30px;

  .margin-out {
    margin: 0 auto;
  }
}

.G-page-title {
  font-size: 14px;
  line-height: 16px;
  color: #000000;
  font-weight: bold;
  margin: 0 auto;
}

.G-header-company-name {
  font-size: 18px;
  line-height: 16px;
  color: #000000;
  font-weight: 400;
}

.G-header-title-date-wrap {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
