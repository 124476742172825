.L-Window-view {
 display: flex;
 justify-content: center;
 align-items: center;
 
    
    .L-Window-img {
        max-width: 686px;
        width: 100%;
    

        img {
            width: 100%;
        }
    }
}




.Testing-text{
    text-align: center;
 text-transform: uppercase;
 font-size: 18px;
 color: red;
   margin-top: 20px;
}