.checkbox-wrapper {
  > span {
    margin-left: 3px;
    font-size: 14px;
    line-height: 14px;
    color: black;
    align-items: center;
    font-weight: 100;
  }
  label {
    input {
      cursor: pointer;
    }
  }
  input[type='checkbox'] {
    accent-color: #076a2a;
  }
}
