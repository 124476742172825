.excel-icon {
  width: 35px;
  height: 35px;
  cursor: pointer;
  margin: 20px 0;
}
.aspect-wrap {
  width: 96%;
  margin-left: 4%;
}

.amount-filed {
  input {
    display: block;
    width: 150px;
    padding: 8px 5px;
    font-size: 14px;
    font-weight: 400;
    line-height: 1.5;
    color: black;
    appearance: none;
    background-color: white;
    background-clip: padding-box;
    border: 1px solid #eee6e6;
    border-radius: 5px;
    transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  }
}
.add-category-type-btn {
  max-width: 200px !important;
  width: 100%;
  button {
    width: 100%;
    background: white !important;
    border: 1px solid #1f7047;
    p {
      color: #1f7047 !important;
    }
    &:hover {
      background: #1f7047 !important;
      p {
        color: white !important;
      }
    }
  }
}

.amount-block {
  tr {
    height: 30px;
    td {
      font-size: 14px;
      font-weight: 100;
    }
  }
}

.budget-summary-section {
  width: 100%;
  height: 100vh;
}
.budget-summary-right-panel {
  width: 96%;
  margin-left: 4%;
}

.budget-summary-flex {
  width: 100%;

  display: flex;

  padding: 10px;
  align-items: flex-start;
}
.budget-summary-area {
  max-width: 920px;
  width: 100%;

  .date-interval-block {
    .date-title {
      font-weight: bold;
    }
  }

  border: 1px solid black;
  padding: 15px 10px;
}

.chart-block {
  width: 25%;

  margin-left: 15px;
  .chart-wrap {
    width: 100%;
    margin-top: 10px;
    h3 {
      text-align: center;
    }
  }
}

.enter-form-modal {
  max-width: 700px;
}

.enter-form {
  .comment-filed,
  .amount-filed,
  .dropdown-block,
  .button-wrap {
    margin-top: 10px;
  }

  .calendar-wrap {
    display: flex;
    flex-direction: column;
    p {
      font-size: 14px;
      margin-bottom: 5px;
    }
  }

  .button-wrap {
    max-width: 95px;
  }
}

.enter-form-title {
  text-align: center;
  font-size: 18px;
  font-weight: 400;
}

.remove-modal {
  max-width: 360px !important;
  width: 100%;
  height: 160px !important;
}

.fin-help-wrap {
  width: 100%;
  height: 100vh;
  overflow: hidden;
}

.checkbox-block {
  display: flex;
  justify-content: space-between;
  max-width: 185px;
  width: 100%;
  .checkbox-wrapper {
    label {
      cursor: pointer;
    }
  }
}

.currency-block {
  .select-control_selectControl__3ER03 {
    max-width: 110px;
    width: 100%;
  }
}

.expense-income-types-block {
  padding: 0 5px;
}

.expense-income-types-block {
  max-width: 200px;
  width: 100%;
}

.report-block {
  max-width: 236px;
  width: 100%;
}
