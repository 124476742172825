.G-account-revaluation-modal {
  max-width: 550px !important;
  width: 100%;
  .G-account-revaluation-flex {
    margin: 0 -10px;
  }
  .G-account-revaluation-panel {
    width: 50%;
    padding: 0 10px;
    p {
      width: 100%;
      margin-top: 14px;
      font-size: 16px;
      font-weight: 200;
      min-height: 22px;
    }
  }
  .L-exchange-rate-input {
    margin-top: 15px;
    .G-input-block {
      width: 70%;
      height: 30px;
    }
  }
}
