.G-modal-background {
  position: fixed;

  z-index: 999999;
  background: rgba(0, 0, 0, 0.6);
  width: 100%;
  height: 100%;

  display: flex;
  justify-content: center;
  align-items: center;
  left: 0;
  top: 0;
}
