
    .year-view,
    .mount-view {
        width: 200px;
    
    }

.mount-view {

    margin-left: 10px;
 
}
