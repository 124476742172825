.salary-logo-block {
  display: flex;
  align-items: center;
  padding-top: 10px;
  padding-left: 40px;
  h3 {
    font-size: 18px;
    margin-left: 5px;
    font-weight: 700;
  }
}
.salary-logo-wrap {
  width: 50px;
  height: 50px;
  display: flex;
  img {
    width: 100%;
    height: 100%;
  }
}
////////////////
$darkGreen: #154632;
.G-search-form-flex {
  display: flex;
  align-items: center;
}

.L-material-values-right-panel {
  width: 96%;
  height: 100vh;
  margin-left: 4%;

  .lds-ellipsis div {
    background: rgb(111, 111, 255);
  }
  .scrollable {
    max-height: unset;
    overflow-y: unset;
  }
  .G-pagination-block {
    background: #23885f;
    .paginationBttns {
      li {
        a {
          color: #092519;
        }
      }
    }
    .paginationBttns {
      &:hover {
        color: #59b38e;
      }
    }
    .nextBttn.paginationDisabled {
      border: 1.8px solid #092519;
      border-left: none;
      border-top: none;
    }
    .previouseBttn.paginationDisabled {
      border: 1.8px solid #092519;
      border-right: none;
      border-bottom: none;
    }
    .paginationActive {
      border: 1px solid #092519;
      a {
        color: #092519;
      }
    }
  }

  .G-button {
    background: $darkGreen;
    border: 1px solid $darkGreen;

    &:hover {
      box-shadow: inset 0px 4px 4px rgb(0 0 0 / 25%);
      background: $darkGreen;
    }
  }

  .move {
    background: #23885f;
  }
  .G-search-bg {
    background: #3ea87d;
  }
  .G-table-header {
    background: $darkGreen;
  }

  .P-toolbar-list {
    .G-toolbar-block {
      li {
        &:hover {
          background: $darkGreen;
        }
      }
    }
  }
  .G-checkbox-wrapper {
    input[type='checkbox'] {
      accent-color: $darkGreen;
    }
  }

  input[type='radio'] {
    accent-color: $darkGreen;
  }
}

.input-container {
  position: relative;
}
.G-confirm-btn-wrap {
  max-width: 150px;
  width: 100%;
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
  margin-top: 15px;
}

.G-up-arrow-block {
  display: flex;
  align-items: center;
  margin-top: 15px;
  margin-left: 5px;
  border-top: 1px solid $darkGreen;
  height: 32px;
  img {
    max-width: 18px;
    width: 100%;
    height: 20px;
    display: flex;

    transform: rotate(178deg);
    cursor: pointer;
  }
  p {
    font-size: 12px;
    line-height: 14px;
    color: black;
    margin-left: 5px;
  }
}

.G-confirm-icon {
  max-width: 80px;
  width: 100%;
  max-height: 23px;
}
.G-confirm-cancel-btn {
  display: flex;
  justify-content: end;
  .G-medium-btn {
    max-width: 100px;
    width: 100%;
    &:first-child {
      margin-right: 10px;
    }
  }
}
