.G-book-main-block {
}

.L-transaction-log-table {
  .G-table-body-cell {
    &:nth-child(8),
    &:nth-child(9) {
      justify-content: flex-end;
    }
  }
  .G-table-body-cell {
    &:nth-child(4),
    &:nth-child(6) {
      justify-content: center;
    }
  }
}
.L-transaction-log-action {
  .G-docTypes-wrapper {
    margin-left: 15px;
  }
}
