.L-entry-order-formation {
  max-width: 1200px;
  width: 100%;
}
.L-entry-order-formation-form {
  display: flex;

  flex-wrap: wrap;
}

.G-entry-order-formation-wrap {
  margin-top: 10px;
}
.G-entry-order-formation-wrap {
  &:nth-child(1),
  &:nth-child(3) {
    max-width: 220px;
    width: 100%;
  }

  &:nth-child(2) {
    .G-material-value-input-wrap {
      width: 80%;
    }
  }
}

.L-field-name-and-value {
  width: 50%;
  h3 {
    font-size: 12px;
    font-weight: 100;
  }
}

.G-entry-order-formation-wrap {
  &:nth-child(6),
  &:nth-child(7) {
    width: 50%;
  }
}
.G-entry-order-formation-wrap {
  &:last-child {
    width: 100%;
  }
}
