.L-partner-balance-book-table {
  .G-table-body-cell {
&:nth-child(3) {
  justify-content: center;
}
  
    &:nth-child(4),
    &:nth-child(5),
    &:nth-child(6) ,
      &:nth-child(7){
      justify-content: flex-end;
    }

  }
}
.partner-balance-book {
  .G-partner-type-wrapper {
    margin-left: 10px;
  }
}
.G-account-is-active {
text-align: start;

  cursor: pointer;

  &:hover {
    display: block;
    text-align: center;
    width: 100%;
    height: 100%;
    background-color: #d5bdbd;
  }
}

.L-partner-account-entries {
  max-width: 1000px;
  width: 100%;

  .G-table-body-cell {


    &:nth-child(6),
    &:nth-child(7) {
      justify-content: flex-end;
    }


  }
}
.partner-balance-book{

  display: flex;
  justify-content: space-between;
  align-items: center;
.L-search-filed{
  max-width: 300px;
  width: 100%;

}

}
