.work-calendar-wrap {
  max-width: 450px;
  width: 100%;

  .G-table-body-cell {
    &:nth-child(1),
    &:nth-child(4) {
      justify-content: center;
    }

    &:nth-child(3) {
      input {
        background: transparent;
        transform: 0.3s;
        width: 100%;
        height: 100%;
        text-align: center;
        &:hover {
          background: #b9b9e9;
          color: white;
        }
      }
    }
  }
  .G-button {
    max-width: 110px;
    margin-top: 10px;
  }
}

.table-info-block {
  .table-info-item {
    display: flex;
    align-items: center;
    margin-top: 10px;
    h3 {
      font-size: 15px;
      font-weight: 600;
    }
    p {
      font-size: 14px;
      margin-left: 10px;
    }
  }
}
