.update-aspect-modal {
  max-width: 650px;
  width: 100%;
  .update-aspect-content {
    margin-top: 15px;
  }
  .form-control {
    margin-top: 10px;
  }

  .button-wrap {
    max-width: 150px;
    margin-top: 10px;
  }
}

.create-enter-modal {
  max-width: 650px;
  width: 100%;
  .dropdown-block,
  .amount-filed,
  .form-control,
  .button-wrap {
    margin-top: 10px;
  }
}

.create-aspect-modal {
  .checkbox-box,
  .button-wrap {
    margin-top: 18px;
    .checkbox-wrapper {
      &:first-child {
        margin-left: 0;
      }
      label {
        cursor: pointer;
      }
    }
  }
}

.category-name-flex {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  margin-top: 15px;
  .category-name-input {
    width: 70%;
  }
}

.create-enter-content {
  .dropdown-block {
    max-width: 100px;
    width: 100%;
  }
}
