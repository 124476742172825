.G-button {
  width: 100%;
  height: 32px;
  text-align: center;
  background: #701937;
  border: 1px solid #701937;
  border-radius: 8px;
  transition: 0.3s;
  cursor: pointer;

  &:hover {
    box-shadow: inset 0px 4px 4px rgb(0 0 0 / 25%);
    background: #8b2a4c;
  }

  > p {
    font-size: 12px;
    line-height: 16px;
    color: white;
  }
}

.G-disabled-button {
  &[disabled] {
    opacity: 0.5;
    cursor: not-allowed;
  }
}
