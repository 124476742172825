$MarginTop: 8px;

.G-cash-order-modal {
  max-width: 560px;
  width: 100%;


  .G-cash-order-content {
    width: 100%;

    .G-cash-order-docNum-block {
      margin-top: $MarginTop;

      .G-form-data-picker {
        margin-left: 15px;
      }
    }

    .G-amount-input {
      max-width: none;
      margin-top: $MarginTop;
    }
    .G-numeric-format-filed {
      input {
        width: 180px;
      }
    }
  }

  .L-order-select-wrapper {
    max-width: 100%;
    width: 100%;
  }

  .G-cash-warrant-input {
    max-width: 100%;
    width: 100%;
    margin-top: $MarginTop;
  }

  .G-partner-wrapper {
    margin-top: $MarginTop;
  }

  .G-cash-warrant-recipient-input {
    width: 65%;
    margin-top: $MarginTop;
  }
}

.L-cash-outflowOrder-table,
.L-cash-warrant-table {
  .G-table-body-cell {
    &:nth-child(2) {
      justify-content: center;
    }

    &:nth-child(5) {
      justify-content: flex-end;
    }
  }
}

.G-currency-account-flex {
  margin-top: $MarginTop;

  .G-currency-wrapper {
    width: 11%;
  }

  .G-select-account-box {
    width: 52%;
  }
  .L-available-amount {
    width: 37%;
  }
}

.G-available-amount {
  font-size: 12px;
  font-weight: normal;
  margin-left: 10px;
}
