.L-change-password-wrapper {
    max-width: 500px;
    width: 100%;
    border: 1px solid #eee;
    padding: 15px;
    margin-top: 15px;
        box-shadow: 0 19px 38px rgb(0 0 0 / 30%), 0 15px 12px rgb(0 0 0 / 22%);

    .L-change-password-input {
        width: 100%;
        margin-bottom: 10px;
        position: relative;
    }

    .L-progress-main-block {
        margin-top: 10px;
    }
}