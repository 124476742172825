.grup {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  align-items: flex-start;
  margin-top: 10px;
  h3 {
    font-size: 14px;
    font-weight: 200;
  }
  .G-checkbox-wrapper {
    width: 50%;
    margin-top: 5px;
  }
}
.calculates-from-additions-group {
  margin-top: 10px;
  overflow-y: auto;
  max-height: calc(32 * 5px);
}

.addition-form-block {
  max-width: 590px;
  .select-container {
    max-width: 300px;
    padding: 0;
    margin-top: 10px;
  }
  .saving-input-container {
    margin-top: 10px;
    &:first-child {
      margin-top: 0;
      width: 20%;
    }
  }
}

.radio-btn-flex {
  display: flex;
  align-items: center;
  margin-top: 10px;
  .G-radio-button {
    &:nth-child(2) {
      margin: 0 10px;
    }
  }
}

.additions-radio-wrap {
  margin-top: 10px;
  .G-title-p {
    font-size: 14px;
    color: black;
    font-weight: 700;
  }
}
