.L-bank-form-modal {
  max-width: 500px;
  width: 100%;
  .L-bank-input-wrapper {
    width: 100%;
    position: relative;
    margin-top: 10px;
  }


}



.L-bank-table{
    .G-table-body-cell {
     &:nth-child(2) {
display: flex;
justify-content: center;
    }
 
  }
}