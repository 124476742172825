.L-questions-list {
  max-width: 909px;
  width: 100%;
  margin: 0 auto;

  h2,
  h1,
  h3,
  p,
  strong {
    color: #545658;
  }
  h2 {
    font-size: 16px;
    text-align: center;
  }
  ul {
    margin-top: 40px;
    li {
      margin-top: 25px;
      h3 {
        padding-bottom: 10px;
      }

      p {
        margin-top: 5px;
      }
    }
  }

  h1 {
    text-align: center;

    font-weight: normal;
  }
}

#L-normal {
  h1 {
    font-size: 20px;
    line-height: 22px;
  }
  h3 {
    font-size: 20px;
    line-height: 22px;
  }

  p {
    font-size: 12px;
    line-height: 18px;
  }
  strong {
    font-size: 16px;
  }
}

#L-medium {
  h1 {
    font-size: 22px;
    line-height: 26px;
  }

  h3 {
    font-size: 22px;
    line-height: 26px;
  }
  strong {
    font-size: 18px;
  }
  p {
    font-size: 14px;
    line-height: 20px;
    margin-top: 12px;
  }
}

#L-large {
  h1 {
    font-size: 25px;
    line-height: 42px;
  }

  h3 {
    font-size: 24px;
    line-height: 42px;
  }
  strong {
    font-size: 20px;
  }
  p {
    font-size: 18px;
    line-height: 25px;
    margin-top: 15px;
  }
}
