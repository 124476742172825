.G-select-user-picture {
  max-width: 300px;
  width: 100%;
  box-shadow: 0 19px 38px rgb(0 0 0 / 30%), 0 15px 12px rgb(0 0 0 / 22%);
  border-radius: 8px;
  padding: 10px;
}

.user-picture {
  margin: 10px auto;
  display: flex;

  img {
    max-width: 170px;
    width: 100%;
    height: 160px;
    border-radius: 8px;
  }
}

.form {
  max-width: 150px;
  width: 100%;
  height: 53px;
  position: relative;
  overflow: hidden;
  border-radius: 10px;
  box-shadow: 1px 1px 2px rgba(0, 0, 0, 0.3),
    -1px -1px 2px rgba(255, 255, 255, 0.3);

  &::after {
    content: '';
    position: absolute;
    background: linear-gradient(90deg, transparent, #943f5d, transparent);
    display: inline-block;
    width: 100%;
    height: 100%;
    left: -100%;
    transform: skewX(35deg);
    animation: slide 1.5s infinite;
  }
}

.sent-img-btn {
  max-width: 140px;
  margin-top: 15px;
}

.choose-img-input {
  display: inline-block;
  border-radius: 5px;

  width: 100%;
  height: 100%;
  position: absolute;
  z-index: 99;
  opacity: 0;
  cursor: pointer;

  .choose-img-input::-webkit-file-upload-button {
    visibility: hidden;
  }
}

.img-text {
  display: flex;
  width: 100%;
  height: 100%;
  position: absolute;
  background-color: #701937;
  top: 0;
  left: 0;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  border-radius: 10px;
  box-shadow: 1px 1px 2px rgba(0, 0, 0, 0.3),
    -1px -1px 2px rgba(255, 255, 255, 0.3);
  color: white;
}

.fa-cloud-upload-alt {
  width: 25px;
  height: 25px;
  animation: upDown 1.2s infinite;
}

.img-text {
  h3 {
    font-size: 14px;
  }
}

@keyframes upDown {
  0% {
    transform: translateY(0);
  }

  50% {
    transform: translateY(-10px);
  }

  100% {
    transform: translateY(0);
  }
}

@keyframes slide {
  0% {
    left: -100%;
  }

  100% {
    left: 200%;
  }
}
