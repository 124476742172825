.G-one-window-view-header-block {
  display: flex;
  justify-content: space-around;
  width: 100%;
  padding: 5px 10px 0;
  background: #DADADA;
  box-shadow: inset 0 1px 1px rgba(255, 255, 255, 0.6),
    inset 0 -1px 1px rgba(0, 0, 0, 0.6), 0 0 5px rgba(0, 0, 0, 0.6);

  .G-window-header-icon-tab {
    width: 90%;
  }
  .G-one-window-view-header-content{
    max-width: 1024px;
    width: 100%;
    margin: 0 auto;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
}