.L-account-analysis-table {
  .G-table-body-cell {


    &:nth-child(3){
      overflow: hidden;
    }
    &:nth-child(6),
    &:nth-child(7) {
      justify-content: flex-end;
    }
  }
}