.G-help-btn {
  position: absolute;
  right: 0;
  top: 0;
  padding: 5px 10px;

  button {
    width: 30px;
    height: 30px;
    border-radius: 50%;
    background-color: white !important;
    border: 1px solid #0005;

    &:hover {
      background-color: white;
    }

    p {
      color: black;
      font-size: 18px;
      font-weight: bold;
      text-align: center;
    }
  }
}
