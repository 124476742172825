.L-EInvoice-entry-form {
  max-width: 1300px;
  width: 100%;
}

.L-EInvoice-entry-form-header {
  width: 80%;
  .list-item {
    padding: 8px 0;
    border-bottom: 2px solid #ddd;
    display: flex;
    justify-content: flex-start;
    font-size: 14px;
    &:last-child {
      border-bottom: none;
    }
    .label {
      font-weight: bold;
      color: #555;
    }
    .value {
      color: #000;
      font-size: 12px;
      display: block;
      margin-left: 15px;
    }
  }
}

.L-EInvoice-entry-btn-block {
  margin-top: 15px;
  .L-EInvoice-entry-btn {
    max-width: 220px;
    width: 100%;
  }
}
