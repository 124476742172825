.L-make-an-addition-block {
  .input-container {
    margin-left: 10px;
  }
  .L-make-an-addition-table {
    input {
      background: transparent;

      height: 100%;
    }
    .G-numeric-format-filed {
      input {
        border: none;
        height: 100%;
        background: transparent;
      }
    }
  }
}
