.help-btn {
    position: absolute;
    right: 0;
    top: 0;
    padding: 5px 10px;
  
    span {
      width: 30px;
      height: 30px;
      border-radius: 50%;
      background-color: white;
      border: 1px solid #0005;
      display: block;
      color: black;
      font-size: 18px;
      font-weight: bold;
      text-align: center;
      cursor: pointer;
 padding: 5px 7px;
  
      &:hover {
        background-color: white;
      }
  
  
    }
  }