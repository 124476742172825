$background-color: #f9f9f9;
$border-color: #ddd;
$text-color: #333;
$label-color: #555;
$value-color: #000;

.L-entryOrder-operation-execution-form {
  max-width: 550px;
  width: 100%;
  .L-entry-operation-execution-table {
    width: 100%;
    margin-top: 20px;
  }
}
.L-entry-execution-container {
  background-color: $background-color;
  border-radius: 8px;
  max-width: 500px;
  width: 100%;
  font-family: Arial, sans-serif;

  .heading {
    text-align: center;
    font-size: 20px;
    color: $text-color;
    margin-bottom: 16px;
  }

  .list {
    .list-item {
      padding: 8px 0;
      border-bottom: 2px solid $border-color;
      display: flex;
      justify-content: flex-start;
      font-size: 14px;
      .G-salary-calender {
        margin-left: 0 !important;
      }

      &:last-child,
      &:nth-last-child(2) {
        border-bottom: none;
      }
      .label {
        font-weight: bold;
        color: $label-color;
      }

      .value {
        color: $value-color;
        font-size: 12px;
        display: block;
        margin-left: 15px;
      }
      .G-salary-calender {
        margin-left: 15px;
      }

      .G-account-wrapper {
        max-width: 100%;
      }
    }
  }
}
