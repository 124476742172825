.income-expense-summary-block {
  margin-bottom: 10px;
  .income-expense-summary-title {
    display: flex;
  }
  .income-expense-summary-title {
    h4 {
      width: 200px;
      font-weight: 100;
      font-size: 14px;
    }
  }
  .income-expense-summary-list {
    margin-top: 18px;
  }
  .income-expense-summary-item {
    display: flex;
    strong {
      display: block;
      width: 200px;
      font-size: 14px;
      font-weight: normal;
    }
  }
}

.excel-icon {
  margin-top: 40px;
  margin-right: 20px;
}
