.G-input-wrapper {
  .G-input-title-flex {
    display: flex;
    align-items: center;
  }

  span {
    font-size: 18px;
    color: red;
    padding-top: 4px;
    padding-right: 5px;
  }

  .G-input-label {
    width: 100%;
    display: flex;

    .G-input-block {
      width: 100%;
      height: 34px;
      background: #fff;
      border-bottom-left-radius: 4px;
      border: 1px solid #850017;
      border-top: none;
      border-right: none;
      transition: 0.3s;
      padding-left: 5px;
      font-size: 13px;

      &:hover {
        border: 1px solid #850017;
        border-top: none;
        border-right: none;
      }

      &:active,
      &:focus {
        border: 1px solid #850017;
        border-top: none;
        border-right: none;
      }

      &::placeholder {
        text-transform: capitalize;
        font-size: 14px;
        line-height: 16px;
        color: #8b8b8b;
      }
    }

    .L-input-disabled {
      border: 1px solid #d9d9d9;
      border-top: none;
      border-right: none;

      &:hover {
        border: 1px solid #d9d9d9;
        border-top: none;
        border-right: none;
      }

      &:active,
      &:focus {
        border: 1px solid #d9d9d9;
        border-top: none;
        border-right: none;
      }
    }
  }
}
