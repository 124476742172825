.L-residue-formation-box {
    max-width: 400px;
    width: 100%;
    padding: 15px;
    border: 1px solid #eee;
    box-shadow: 0 19px 38px rgb(0 0 0 / 30%), 0 15px 12px rgb(0 0 0 / 22%);

    h3 {
        font-size: 16px;
        font-weight: 100;
        text-align: center;
    }

    .G-view-btn {
        margin-top: 10px;
    }


}

.L-accounting-period-box {
    margin-top: 20px;
    display: flex;

    p {
        margin-left: 10px;
    }
}