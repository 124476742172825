.repdetal-contine {
}

.operation-table {
  .table-thead {
    tr {
      th {
        &:nth-child(1) {
          width: 80px;
        }
        &:nth-child(2) {
          width: 150px;
        }
        &:nth-child(3) {
          width: 200px;
        }
        &:nth-child(4) {
          width: 100px;
        }
        &:nth-child(5) {
          width: 220px;
        }
      }
    }
  }
}

.data-entry-table {
  .table-thead {
    tr {
      th {
        font-weight: unset;
        &:nth-child(6) {
          width: 350px;
        }
      }
    }
  }
}

.table-th {
  th {
    font-size: 16px;
  }
}

.tbody-td {
  td {
    font-size: 14px;
  }
}

.income-expenseSummary-flex {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
