.category-box .card-body .icon-box > img {
  width: 15px;
  height: 15px;
}

.category-box .card-body > button {
  margin-left: 15px;
}

.icon-box {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.ml-15 {
  margin-left: 15px;
}

.card-title {
  font-size: 15px !important;
}

.select-action-btn {
  display: flex;

  > button {
    background-color: #116e43;
    border-color: #116e43;
    color: #fff;

    &:nth-child(2) {
      margin: 0 5px;
    }

    &:hover {
      color: #fff;
    }
  }
}

.select-action {
  margin-top: 10px;
}

.border-bottom {
  border-bottom: 2px solid #116e43 !important;
}

.btn-column {
  display: flex;
  align-items: center;
}

.expense-income-btn {
  margin-top: 5px;
  width: 80px;
  height: 20px;
  font-size: 12px;
}

.category-action {
  margin-left: 18px;
  cursor: pointer;
}
.action-icon-wrap {
  margin-left: 15px;
}
.category-action-top {
  margin-top: 15px;
}

.category-action > img {
  width: 18px;
  height: 18px;
}

.select-type-dow {
  display: flex;

  span {
    display: block;
    margin-left: 15px;
  }
}

.select-type-dow {
  width: 230px;

  .type-img-box {
    width: 40px;
    height: 40px;
    display: flex;
    align-items: center;
    border-radius: 50%;
  }

  img {
    width: 80%;
    height: 80%;
    border-radius: 50%;
  }
}

.btn-primary {
  background-color: #008481;
  border-color: #008481;

  &:hover {
    background-color: #008481;
    border-color: #008481;
  }
}

.btn-success {
  background-color: #116e43;
  border-color: #116e43;

  &:hover {
    background-color: #116e43;
    border-color: #116e43;
  }
}

.currency-table {
  th,
  td {
    width: 70px;
    font-size: 14px !important;
    font-weight: 100;
    color: #000;
    height: 30px;
    text-align: start;
    &:nth-child(3) {
      width: 110px;
    }

    &:nth-child(1) {
      width: 130px;
    }
  }
  th {
    font-weight: bold;
    font-size: 16px !important;
  }
}

.currency-wrap {
  width: 25%;
}

.filter-btnS {
  width: 80%;
  margin-left: 61px;
}

.aspect-container {
  margin-top: 15px;
  background-color: #fffbfb;
}

.aspect-container-flex {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  padding: 0 10px;
}

.left-panel {
  width: 35%;
  height: 80vh;
  overflow-x: auto;
  .aspect-flex {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    margin: 5px 5px;
  }
}

.right-panel {
  width: 65%;

  padding-left: 15px;
}
.chart-marginTop {
  margin-top: 50px;
  margin-left: 20px;
}

.expense-income-btn-box {
  margin-top: 5px;

  &:last-child {
    margin-left: 15px;
  }
}

.btn-color {
  button {
    background-color: #116e43;
    border-color: #116e43;

    &:hover {
      background-color: #116e43;
      border-color: #116e43;
    }

    &:focus {
      background-color: #116e43;
      border-color: #116e43;
    }
  }
}

.checkbox-box {
  display: flex;
  margin-right: 15px;
}

.has-size {
  font-size: 21px !important;
  text-align: left;
}

.font-size {
  font-size: 18px;
}

.drop-btn {
  button {
    border-radius: 8px !important;
  }
}

.mT-50 {
  margin-top: 50px;
}

.mT-20 {
  margin-top: 20px;
}

.g-btn {
  max-width: 200px;
  width: 100%;

  > button {
    width: 100%;
    height: 38px;
    color: #fff;
    background-color: #116e43;
    border-color: #116e43;
    border-radius: 8px;
  }
}

.graf-block {
  margin-left: 25px;
}

.note-table {
  width: 100%;
  margin-top: 25px;
  th {
    margin: 0 15px;
  }

  tbody {
    tr {
      td {
        margin: 0 15px;

        &:first-child {
          width: 900px;
        }

        &:nth-child(2),
        &:nth-child(3),
        &:nth-child(4) {
          width: 200px;
        }

        &:last-child {
          width: 80px;
        }
      }
    }
  }
}

.aspect-item-wrap {
  display: flex;
  margin-top: 10px;
}

.excel-img-block {
  width: 40px;
  height: 40px;
}

.total-rate {
  .total-rate-content {
    margin-top: 10px;
    display: flex;
  }
  strong {
    font-weight: bold;
    font-size: 14px;
  }
  p {
    font-size: 14px;
    margin-left: 20px;
  }

  p + p {
    margin-left: 10px;
  }
  span {
    display: block;
    font-weight: bold;
    font-size: 11px;
    margin-top: 5px;
  }
}
