.assign-additional-storage-modal {
  max-width: 1200px;
  width: 100%;
}

.assign-storage-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.assign-storage-header-item {
  display: flex;
  align-items: center;
  h4 {
    font-weight: 100;
  }
  span {
    display: block;
    margin-left: 5px;
    font-size: 16px;
  }
}
.assign-additional-form-block {
  max-width: 450px;
  width: 100%;
  .input-container {
    margin-top: 10px;
    &:first-child {
      max-width: 270px;
      width: 100%;
    }

    .G-numeric-format-filed {
      input {
        max-width: 160px;
        width: 100%;
      }
    }
  }
  .select-container {
    margin-top: 10px;
  }
  .assign-additional-form-content {
    .flex-field-container {
      display: flex;
      justify-content: space-between;
      flex-wrap: wrap;

      margin: 0px -5px -5px 0;
      .flex-field-item {
        width: 50%;
        padding: 10px 5px 5px 0;
        &:nth-child(3) {
          width: 20%;
        }
      }
    }
  }
}

.L-assign-position-modal {
  max-width: 999px;
  width: 100%;
}
