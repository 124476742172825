.G-currency-wrapper {
  max-width: 140px;
  width: 100%;
}
.G-select-account-box {
  position: relative;
}
.G-docTypes-wrapper {
  max-width: 300px;
  width: 100%;
}
.G-partner-wrapper {
  max-width: 300px;
  width: 100%;
}

.G-account-wrapper {
  max-width: 350px;
  width: 100%;
}

.G-bank-wrapper {
  max-width: 350px;
  width: 100%;
}

.G-partner-type-wrapper {
  max-width: 300px;
  width: 100%;
}
