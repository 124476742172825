@import "../../Assets/Scss/globalStylers.scss";

.L-accounting-correspondence-table {
  .G-table-body-cell {
    &:nth-child(3) {
      @include whiteSpaceNowrap;
    }
  }

}

.L-chose-debit-or-credit {
  .L-debit-credit-box {
    margin-left: 15px;
  }
}