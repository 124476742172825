.confirm-action-wrap {
  width: 75%;
  display: flex;
  align-items: center;
  .G-select-control {
    max-width: 250px;
    width: 100%;
    margin-left: 10px;
    &:first-child {
      margin-left: 0;
    }
  }
  .G-salary-calender {
    max-width: 100px;
  }
}
.confirm-action-wrap-flex {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.L-confirm-positions-table {
  input {
    width: max-content;
    height: 100%;
    background: transparent;
    font-size: 14px;
  }
}

.L-confirm-positions-wrap {
  .L-confirm-positions-btn {
    max-width: 150px;
    width: 100%;
    display: flex;
    justify-content: flex-end;
    align-items: flex-end;
    margin-top: 15px;
  }
}
.L-confirm-positions-table {
  .G-table-body-cell {
    img {
      max-width: 60px;
      width: 100%;
      height: 25px;
    }
  }
}

.L-conf-pos-act-btn-flex {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  .L-confirm-positions-btn {
    &:first-child {
      max-width: 120px;
      margin-right: 15px;
    }
  }
}
