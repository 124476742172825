.L-accounting-formulation-table {
  .G-table-body-cell {
    &:nth-child(3) {
      justify-content: end;
    }
  }
}

.L-accounting-formulation-action-flex {
  display: flex;
  align-items: center;
  justify-content: space-between;

  .G-select-control {
    max-width: 195px;
    width: 100%;
    &:last-child {
      margin-left: 10px;
    }
  }
}

.L-formulation-btn-flex {
  display: flex;
  align-items: center;
  justify-content: flex-end;

  .G-formulation-btn {
    max-width: 130px;
    width: 100%;
    &:first-child {
      max-width: 90px;
      width: 100%;
      margin-right: 10px;
    }
  }
}

.L-formulation-dropdown-flex,
.L-print-and-formulations-btn {
  width: 50%;
  display: flex;
  .G-select-control {
    max-width: 170px;
    width: 100%;
  }
}

.L-formulation-remove-conf-btn {
  margin-top: 15px;
}
