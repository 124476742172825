.G-one-window-main-section {
  width: 100%;
  height: 100vh;
}

#G-normal {
  font-size: 15px;
  line-height: 21px;

  h3 {
    font-size: 20px;
    line-height: 22px;
  }

  p,
  span {
    font-size: 12px;
    line-height: 18px;
  }
}

#G-medium {
  h3 {
    font-size: 22px;
    line-height: 26px;
  }

  p,
  span {
    font-size: 14px;
    line-height: 20px;
    margin-top: 12px;
  }
}

#G-large {
  h3 {
    font-size: 25px;
    line-height: 42px;
  }

  p,
  span {
    font-size: 18px;
    line-height: 25px;
    margin-top: 15px;
  }
}

.one-window-view-wrapper {
  padding-top: 20px;
  padding-bottom: 20px;

  background-color: white;
  min-height: 90vh;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;

  .G-button {
    p {
      margin-top: 0 !important;
    }
  }
}
