.G-docNum-input {
  max-width: 150px;
  width: 100%;
}

.G-amount-input {
  max-width: 180px;
  width: 100%;
  position: relative;
}
