.has-scroll {
  overflow-y: auto;

  &::-webkit-scrollbar {
    width: 7px;
    height: 8px;
    background: #f5f5f5;
    border-radius: 100px;
  }

  &::-webkit-scrollbar-thumb {
    background-color: #858a95;
    width: 8px;
    -webkit-border-radius: 10px;
    border-radius: 10px;
    cursor: pointer;
  }
}

.scrolling-table {
  max-height: calc(32 * 20px);
}

.my-table {
  margin-top: 10px;
  table {
    border-collapse: collapse;
  }
  .table-thead {
    position: sticky;
    top: 0;
  }
  td {
    vertical-align: middle;
  }
  .table-thead {
    background-color: #076a2a;
    color: white;
    font-size: 14px;

    th {
      border-left: 1px solid #0004;
      font-weight: unset;
    }
    tr,
    th {
      vertical-align: middle;
      height: 32px;
    }
  }
  .table-tbody {
    tr {
      background-color: #eeee;
      font-size: 13px;

      &:nth-child(even) {
        background: #ffffff;
      }
      td {
        border-right: 1px solid #a7a3a3;
        padding: 0 5px;
      }
    }
  }

  .tr-size {
    height: 32px;
    border-bottom: 1px solid #eee;
  }
}

.category-action {
  margin-left: 18px;
  cursor: pointer;
}
.action-icon-wrap {
  display: flex;
  margin-left: 15px;
}
.category-action-top {
  margin-top: 15px;
}

.category-action > img {
  width: 18px;
  height: 18px;
}
