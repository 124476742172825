@mixin inputBorderStyles {
  padding-left: 27px;
  background: #ffffff;
  border: 1px solid #c13499 !important;
  border-radius: 4px;
  &::placeholder {
    font-size: 12px;
  }
}

@mixin saveCenter {
  position: absolute;
  left: 10px;
  top: 50%;
  transform: translateY(-50%);
}

@mixin tableScrollBar {
  display: block;
  max-height: calc(32 * 5px);
  overflow-y: auto;

  &::-webkit-scrollbar {
    width: 7px;
    height: 8px;
    background: #f5f5f5;
    border-radius: 100px;
  }

  &::-webkit-scrollbar-thumb {
    background-color: #858a95;
    width: 8px;
    -webkit-border-radius: 10px;
    border-radius: 10px;
    cursor: pointer;
  }
}

@mixin largeBtn {
  max-width: 120px;
  width: 100%;
}

@mixin textEllipsis {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  display: inline-block !important;
}

@mixin whiteSpaceNowrap {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
