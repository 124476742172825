.G-add-icon {
    position: relative;
    width: 40px;
    height: 36px;
    border: 1px solid hsl(0, 0%, 80%);
    border-left: none;
    -webkit-border-top-right-radius: 4px;
    -webkit-border-bottom-right-radius: 4px;
    -moz-border-radius-topright: 4px;
    -moz-border-radius-bottomright: 4px;
    border-top-right-radius: 4px;
    border-bottom-right-radius: 4px;
    margin-top: 31px;
    display: flex;
    justify-content: center;
    align-items: center;

    &::before,
    &::after {
        content: '';
        position: absolute;
        width: 14px;
        height: 2px;
        background-color: hsl(0, 0%, 80%);
        transition: 0.3s;
        cursor: pointer;
    }

    &::after {
        transform: rotate(-91deg);
    }

}

.Tooltip-Tip .top {
    top: -3px !important;
}