.provision-box {
  width: 50%;
  padding: 15px 10px;
  .calendar-wrap {
    width: 100%;
    margin-top: 10px;
    display: flex;
    .react-datepicker-wrapper {
      margin-left: 10px;
    }
  }
}

.provision-box-content {
  width: 100%;
  border: 1px solid #3c439c;
  border-radius: 4px;
  background-color: #fff;
  box-shadow: 0 19px 38px rgba(0, 0, 0, 0.3);
  padding: 10px;
  height: 290px;
}
.provisions-list-wrap {
  width: 50%;
  border: 1px solid #3c439c;
  border-radius: 4px;
  background-color: #fff;
  box-shadow: 0 19px 38px rgba(0, 0, 0, 0.3);
  padding: 10px;
}

.L-provisions-list {
  margin-top: 10px;
  display: flex;

  h3 {
    font-size: 14px;
    font-weight: 100;
  }
  p {
    margin-left: 15px;
  }
}
.provision-box-content {
  h2 {
    font-size: 16px;
    font-weight: 100;
  }
}
.provisions-title {
  margin-top: 10px;
  display: flex;
  p {
    font-size: 14px;
    font-weight: 600;
  }
  span {
    font-size: 14px;
    display: block;
    margin-left: 10px;
  }
}
