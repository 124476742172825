.G-action {
  width: 100%;
  display: flex;
  justify-content: space-around;
  align-items: center;
  position: relative;
  cursor: pointer;
  .G-toolbar-block {
    text-align: center;
  }
}
