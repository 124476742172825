.L-company-wrap {
  .G-header-bot-area {
    justify-content: flex-start;
  }
}

.L-organization-form {
  max-width: 1120px;

  width: 100%;

  border: 1px solid #eee;
  box-shadow: 0 19px 38px rgba(0, 0, 0, 0.3), 0 15px 12px rgba(0, 0, 0, 0.22);
  .L-organization-form-content {
    width: 100%;
    .G-checkbox-wrapper {
      max-width: 90px;
      width: 100%;

      margin-left: 10px;
    }
    .L-organization-form-boxes-flex {
      margin: -5px -10px;

      .L-organization-box-wrapper {
        width: 33%;
        padding: 5px 10px;
      }

      .L-organization-form-box {
        width: 100%;
        position: relative;
      }
    }
  }
  .L-reporting-period-form {
    display: flex;

    margin-top: 15px;
  }
}

.L-organization-table {
  .G-table-body-cell {
    &:nth-child(4) {
      justify-content: flex-end;
    }
  }
}

.L-organization-add-form {
  margin: 0 auto;

  h3 {
    text-align: center;
    margin: 15px auto;
    font-size: 16px;
    font-weight: 100;
  }
}

.L-organization-wrapper {
  padding-top: 25px;

  .L-organization-title {
    margin-bottom: 15px;

    h3 {
      font-weight: 100;
      font-size: 15px;
      text-align: center;
    }

    .G-help-btn {
      right: 45px;
    }
  }
}

.L-organization-form-boxes-flex {
  display: flex;
  align-items: flex-start;

  .L-reporting-period-wrap {
    width: 33%;
    padding: 12px;
    display: flex;
    justify-content: space-between;
    margin-top: 25px;
    border: 1px solid #737373;
    border-radius: 4px;
    position: relative;

    .L-reporting-period-box {
      .G-title-p {
        padding-left: 3px;
      }
    }
  }
}

.L-organization-action-btn {
  margin-top: -10px;
  .G-init-bal-btn {
    max-width: 226px;
    width: 100%;
  }
  .G-partner-btn {
    max-width: 155px;
    width: 100%;
    margin: 0 10px;
  }
}

.L-organization-router-btn {
  display: flex;
  justify-content: space-between;
  padding-top: 20px;
  .G-bank-btn {
    max-width: 120px;
    width: 100%;
  }
  .G-register-btn {
    margin-right: 50px;
  }
}

.L-organization-action-btn {
  max-width: 550px;
  width: 100%;
  display: flex;
}

.L-taxation-block {
  border: 1px solid #737373;
  border-radius: 4px;

  .L-reporting-period-title {
    left: 39%;
    top: -15px;
    transform: unset;
  }
  .L-taxation-block-content {
    width: 100%;

    display: flex;
    position: relative;
    padding: 0 8px;
  }
}

.L-reporting-period-title {
  position: absolute;
  left: 50%;
  top: -10px;
  transform: translate(-50%, 0);
  background-color: #fffbfb;
  font-size: 12px;
}

.L-comp-radio-btn {
  display: flex;
  margin-top: 10px;
  .G-radio-button {
    &:last-child {
      margin-left: 10px;
    }
  }
}
