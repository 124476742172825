.L-company-taxation-system-form {
  max-width: 450px;
  width: 100%;
  padding: 23px 10px;
  border: 1px solid #eee;
  box-shadow: 0 19px 38px rgba(0, 0, 0, 0.3), 0 15px 12px rgba(0, 0, 0, 0.22);
  height: 240px;
  margin-top: 25px;
  &:last-child {
    margin-left: 18px;
  }
}
.L-company-taxation-system-flex {
  display: flex;
  align-items: flex-start;
}

.taxation-form-section {
  border: 1px solid rgb(110, 101, 101);
  padding: 5px 8px;
  border-radius: 3px;
  margin-top: 20px;
  &:first-child {
    margin-top: 0;
  }
}

.L-taxation-system-history {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 20px;
}
.taxation-form-date-flex {
  display: flex;
  .L-reporting-period-box {
    &:last-child {
      margin-left: 15px;
    }
  }
}

.form-actions {
  display: flex;
  justify-content: end;
  margin-top: 20px;
}

.L-taxation-history-link {
  font-size: 12px;
  color: #701937;
  cursor: pointer;

  padding: 5px;
  border-radius: 3px;
  transition: 0.3s;
  &:hover {
    box-shadow: inset 0 4px 4px rgba(0, 0, 0, 0.25);
  }
}

.G-company-taxationSystem-form-actions {
  width: 100%;
  display: flex;
  justify-content: flex-end;
  margin-top: 10px;
  .G-button {
    max-width: 100px;
    width: 100%;
  }
}
