.G-select-title {
  display: flex;
  align-items: center;

  .G-title-p {
    margin-left: 5px;
  }
}

.G-asterisk {
  font-size: 18px;
  color: red !important;
  padding-left: 5px;
  padding-top: 4px;
}

.css-1s2u09g-control {
  height: 35px !important;
  min-height: 0 !important;
}

.css-tlfecz-indicatorContainer {
  svg {
    width: 20px !important;
    height: 20px !important;
  }
}

.css-1pahdxg-control {
  height: 35px !important;
  min-height: 0 !important;

  &:hover,
  &:focus,
  &:active {
    // border-color: red !important;
    // box-shadow: 0 0 0 1px red !important;
  }

  svg {
    width: 20px !important;
    height: 20px !important;
  }
}

.css-qc6sy-singleValue {
  font-size: 13px;
}

.css-6j8wv5-Input {
  font-size: 12px !important;
}
.G-select-control {
  .css-1s2u09g-control {
    cursor: pointer;
  }
}

.css-qc6sy-singleValue {
  font-size: 12px;
}

.G-select-wrapper {
  &:active {
    border-color: yellow !important;
  }
}

.G-select-wrapper {
  font-size: 12px !important;
}
