.header {
  width: 100%;
  padding: 5px 10px;
  background-color: #9bcfad;
}

.header-block {
  display: flex;
  align-items: center;
}

.page-title {
  width: 85%;
  text-align: center;
}

.page-title h3 {
  font-size: 20px;
  line-height: 16px;
  color: #000000;
  font-weight: 400;
}
