.L-partner-circulation-bulletin-block {
  align-items: center;
  .L-partner-check-account {
    span {
      font-size: 14px;
    }
  }
}

.L-partner-view-account-table {
  .G-table-body-cell {

    &:nth-child(3),
    &:nth-child(4),
    &:nth-child(5),
    &:nth-child(6),
    &:nth-child(7),
    &:nth-child(8) {
      justify-content: flex-end;
    }
  }
}
