.G-material-value-input-wrap {
  width: 100%;
}
.L-material-value-name-filed {
  position: relative;
}
.G-s-input-title {
  display: flex;
  align-items: center;
  .G-title-p {
    font-size: 12px;
    line-height: 24px;
    color: #1e1515;
    font-weight: 400;
    padding-left: 5px;
  }
}
.G-material-value-input {
  width: 100%;
  height: 34px;
  background: #fff;
  border-bottom-left-radius: 4px;
  border: 1px solid #154632;
  border-top: none;
  border-right: none;
  transition: 0.3s;
  padding-left: 5px;
  font-size: 13px;
}

.has-border {
  width: 100%;
  height: 34px;
  background: #fff;
  border: 1px solid #154632 !important;
  border-radius: 4px;
  transition: 0.3s;
  padding-left: 5px;
  font-size: 13px;
}
