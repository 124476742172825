.one-window-view-wrapper {
  > .L-who-are-we-block {
    max-width: 800px;
    width: 100%;
    margin: 0 auto;
    &:last-child {
      margin-top: 20px;
    }
    h3 {
      text-align: center;
      font-size: 20px;
      color: #545658;
      font-weight: normal;
    }

    p {
      font-size: 18px;
      color: #545658;
      line-height: 25px;
      margin-top: 15px;
    }
    span {
      color: blue;
      cursor: pointer;
    }
  }
}
.has-dot {
  position: relative;
  margin-left: 18px;
  &::before {
    content: '';
    position: absolute;
    left: -15px;
    top: 10px;
    width: 5px;
    height: 5px;
    background-color: black;
    border-radius: 50%;
  }
}
