.L-material-values-partner-form {
  max-width: 550px;
  width: 100%;
  .L-material-values-partner-filed {
    margin-top: 10px;
  }
  .L-partner-radio-block {
    .radio-btn-flex {
      margin: 0;
      width: 40%;
      border: 1px solid #737373;
      border-radius: 4px;
      padding: 8px 5px;
      display: flex;
      align-items: center;
      .G-radio-button {
        &:last-child {
          margin-left: 10px;
        }
      }
    }
  }
}
.L-partner-personal-data {
  display: flex;
  .L-partner-personal-filed {
    &:nth-child(2) {
      margin: 0 10px;
    }
  }
}
