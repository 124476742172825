.G-book-main-block {
  display: flex;
  align-items: center;
  .G-currency-wrapper {
    margin-left: 15px;
  }
  .L-book-check-account {
    width: 100%;
    margin-left: 45px;
    .G-checkbox-wrapper {
      span {
        font-size: 14px !important;
      }
    }
  }
}

.G-t-account-form-flex {
  display: flex;
  justify-content: space-between;
  align-items: center;

}
