.G-initial-final-balance {
  margin: 20px 0;

  strong {
    display: block;
    font-size: 14px;
padding-right:128px ;
font-weight: 100;
    &:last-child {
      padding-right: 120px;
    }
  }
}

//