.form-control{
    
label{
    input{
    display: block;
    width: 100%;
      padding: 8px 5px;
    font-size: 14px;
    font-weight: 400;
    line-height: 1.5;
    color: black;
    appearance: none;
    background-color:white;
    background-clip: padding-box;
    border: 1px solid #eee6e6;
    border-radius: 5px;
    transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    }
}
}