@mixin inputStyle {
    width: 100%;
    border-radius: 5px;

    &::placeholder {
        font-size: 12px;
        color: #4E5052;
        padding-left: 10px;

    }
}

.contact-us-block {
    max-width: 900px;
    width: 100%;
    margin: 0 auto;
    box-sizing: border-box;
}

.contact-us-content {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    margin-top: 20px;
    margin: 20px -10px 0 -10px;

    .contact-us-address-and-phone {
        display: flex;
        padding: 15px 0 15px 20px;

        >p {
            font-size: 18px;
            color: #545658;
            line-height: 28px;
        }
    }

    .contact-us-title-area {
        width: 100%;
        height: 35px;
        background-color: #6096B3;
        color: white;
        font-size: 22px;
        font-weight: 500;
        position: relative;
        z-index: 5;



        span {
            display: block;
            background-color: inherit;
            height: 100%;
            padding-left: 15px;
            display: flex;
            align-items: center;
        }

        &::before,
        &::after {
            content: "";
            position: absolute;
            display: inline-block;
            width: 6px;
            background-color: #0B689A;
            height: 35px;
        }

        &::before {

            transform: skewY(250deg);
            transform-origin: left;
            z-index: -1;
        }

        &::after {


            right: 0;
            transform: skewY(110deg);
            transform-origin: right;
            z-index: -1;
            top: 0;
        }
    }
}

.contact-us-address-panel {
    width: 60%;
    padding: 20px 10px 0 10px;

    .iframe-wrapper {
        margin-top: 15px;

        >iframe {
            width: 100%;
            height: 400px;
            display: block;
        }
    }

}

.contact-us-phone-panel {
    width: 40%;
    padding: 20px 10px 0 10px;

    .write-to-us-form {
        width: 100%;

        .write-to-us-input {
            margin-top: 10px;

            .G-input-block {
                @include inputStyle;

            }
        }


    }

    .write-to-us-textarea {
        margin-top: 10px;

        >textarea {
            height: 140px;
            resize: none;
            @include inputStyle;
            border: 1px solid #318fe4;

            &::placeholder {
                padding-top: 10px;

            }
        }

    }

    .sent-btn {
        display: flex;
        justify-content: center;

        .G-button {
            max-width: 140px;
            width: 100%;
            border-radius: 12px;
            background: linear-gradient(45deg, #314D5C, #547486, #547486, #314D5C) !important;
            transform: 0.3s;
            box-shadow: inset 0 3px 6px 0 hsl(0deg 0% 100% / 30%), inset -2px -3px 6px 0 rgb(0 0 0 / 60%), 1px 1px 6px 1px rgb(0 0 0 / 60%), 1px 1px 3px 0 hsl(0deg 0% 100% / 30%);
            border: 1px solid #000;

            &:hover {
                background: linear-gradient(45deg, #2C568D, #558DD7, #2C568D, #558DD7) !important;
            }

        }
    }

}