.date-interval-block {
  max-width: 220px;
  width: 100%;
  .react-datepicker__input-container {
    input {
      font-size: 13px !important;
    }
  }
}

.date-title {
  font-size: 13px;
  line-height: 24px;
  color: #1e1515;
  font-weight: 400;
}

.date-interval-flex {
  display: flex;
  justify-content: center;
  margin-top: 4px;
}

.date-start-end + .date-start-end {
  margin-left: 15px;
}
