.L-general-table-wrap {
  overflow-x: scroll;

  ::-webkit-scrollbar {
    width: 12px;
    height: 12px;
  }

  ::-webkit-scrollbar-track {
    background: #f1f1f1;
  }

  ::-webkit-scrollbar-thumb {
    background: #888;
    border-radius: 10px;
  }

  ::-webkit-scrollbar-thumb:hover {
    background: #555;
    cursor: pointer;
  }

  scrollbar-color: #888 #f1f1f1;
  scrollbar-width: thin;

  .L-general-summary-table {
    margin-top: 25px;
    .G-table-body-cell {
      &:nth-child(n + 4) {
        justify-content: flex-end;
      }
    }
  }
}

.L-general-summary-action-wrap {
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  .L-general-summary-filed-flex {
    display: flex;
    align-items: center;
    max-width: 920px;
    width: 100%;
    .L-general-summary-filed {
      max-width: 200px;
      width: 100%;
      margin-left: 10px;
      &:first-child {
        margin-left: 0;
        max-width: 180px;
        width: 100%;
      }

      &:nth-child(3) {
        max-width: 280px;
        width: 100%;
      }
      &:nth-child(5) {
        max-width: 250px;
        width: 100%;
      }
    }
  }
}
