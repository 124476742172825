.G-checkbox-wrapper {
  > span {
    margin-left: 5px;
    font-size: 12px;
    line-height: 14px;
    color: #000;
    align-items: center;
  }
  input {
    cursor: pointer;
  }
}

.G-checkbox-wrapper {
  input[type='checkbox']:disabled {
    cursor: not-allowed;
  }
  input[type='checkbox'] {
    accent-color: #750015;
  }
}
