.calendar-wrapper {
}

.G-input-group-calender {
  max-width: 210px;
  width: 100%;
  display: flex;

  .input-group-prepend {
    border: 1px solid #8b8b8b;
    input {
      width: 80px;
      height: 32px;
    }
  }
}

.react-datepicker-wrapper {
  .form-control {
    width: 100px;
    height: 34px;
    text-align: center;
    border: 1px solid #737373;
    border-radius: 4px;
    font-size: 12px;
    line-height: 16px;
    color: black;
    cursor: pointer;
  }
}
.G-input-group-calender {
  .form-control {
    width: 100px;
    height: 32px;
    border: 1px solid #8b8b8b;
  }
  .select-form-control {
    width: 140px;
    height: 32px;
    border: 1px solid #8b8b8b;
    option {
      font-size: 14px;
      text-transform: capitalize;
    }
  }
}
.select-control {
  width: 100px;
  height: 32px;
  border: 1px solid #ced4da;
}
.react-datepicker__month-container {
  width: 256px !important;
}
.input-group-prepend,
.input-group-append {
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid #6c757d;

  .btn {
    width: 21px;
    height: 32px;
    color: #6c757d;
    font-size: 15px;
  }
}

.date-title {
  font-size: 12px;
  line-height: 24px;
  color: #1e1515;
  font-weight: 400;
}
