.work-guide-title-list {
  display: flex;
  align-items: flex-start;
  justify-content: center;
  flex-direction: column;

  h3 {
    font-size: 14px;
    margin-bottom: 3px;
  }
  li {
    padding: 5px 0;
    margin-left: 20px;
a{
      font-size: 14px;
      color: #000;
    &:hover {
      cursor: pointer;
      color: #3838e4;
    }
}
  }
}
