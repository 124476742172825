.employees-form-block {
  max-width: 800px;
  padding: 10px 2px;
  height: 600px;
}

.tab-content {
  margin: -6px -10px;
}

.tab-buttons {
  display: flex;
}

.tab-btn,
.tab-btn-isActive {
  border: 1px solid #000;
  padding: 2px 4px;
  border-radius: 3px;
  background: none;
  font-size: 14px;
  &:first-child {
    margin-left: 0;
  }
  margin-left: 10px;
}

.tab-btn-isActive {
  background: #3c439c;
  border: 1px solid #3c439c;
  color: white;
}

//start personal card style

.L-personal-card-comp {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
  margin-top: 20px;
  .G-checkbox-wrapper,
  .select-container,
  .input-container,
  .date-block {
    width: 33.333%;

    padding: 6px 10px;
    &:first-child {
      .G-s-input-wrap {
        width: 50%;
      }
    }
  }
}

//start calculation Data  style

.L-calculation-data-comp {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
  margin-top: 20px;
  .G-checkbox-wrapper,
  .G-s-input-wrap,
  .select-container {
    width: 50%;
    padding: 6px 10px;
    margin: 0;
  }
  .G-s-input-wrap {
    .G-s-input {
      width: 20%;
    }
  }
}

.employees-radio-flex {
  display: flex;

  margin-top: 5px;

  .G-radio-button {
    &:nth-child(2) {
      margin-left: 10px;
    }
  }
}

// start other Data  style

.other-data-comp {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
  margin-top: 20px;

  .input-container {
    width: 50%;
    padding: 6px 10px;
    margin: 0;
  }
}

// start employees filter  style
.employees-filter-block {
  display: flex;
  align-items: center;
  justify-content: space-between;
  .G-checkbox-wrapper {
    max-width: 220px;
    width: 100%;
    margin-left: 10px;
  }
}
.employees-filter-flex {
  display: flex;
  max-width: 900px;
  width: 100%;
  .select-container,
  .input-container {
    max-width: 250px;
    width: 100%;
    &:first-child {
      margin-left: 0;
    }

    margin-left: 15px;
  }
}
