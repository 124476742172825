.radio-btn-block {
  display: flex;
  align-items: center;

  > label {
    font-size: 13px;
    color: black;
    margin-top: 5px;
  }

  > input {
    cursor: pointer;
  }
  input[type='radio'] {
    accent-color: #076a2a;
  }
}
