.G-help-modal {
  max-width: 630px;
  width: 100%;
  border-radius: 15px;
}

.G-helper-p {
  font-size: 14px;
  line-height: 20px;
  text-align: left;
  margin-top: 10px;
}

.L-togl-text {
  font-size: 16px;
  color: blue;
  cursor: pointer;
}
.details {
  font-size: 16px;
  color: #8f0ff4;
  cursor: pointer;
}
.G-helper-content{
  
    a{
     color: blue;
    margin-left: 14px;
    font-size: 15px;
    font-style: italic;
    }
  
}