@import "../../Assets/Scss/globalStylers.scss";


.L-document-table {
  .G-table-body-cell {
    &:nth-child(7) {
      @include whiteSpaceNowrap;
    }
  }

  .G-table-body-cell {
     &:nth-child(4) {
     overflow: hidden;
    }
 
    &:nth-child(6) {
      justify-content: flex-end;
    }
  }
      .G-table-body-cell {
    &:nth-child(5) {
      justify-content:center;
    }
  }
}


.L-document-search-box{
  display: flex;
  justify-content:space-between;
  flex-wrap: wrap;
}

.L-document-search-btn{
    display: flex;
  justify-content: flex-end;
  align-items: center;
  margin-top: 15px;
  .G-search-btn{
    margin: 0 auto
  }
}