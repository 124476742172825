.category-box-title {
    h3 {
        font-size: 16px;
        font-weight: 400;
    }
}



.category-box-wrap{
          padding: 5px 5px;
        width: 50% ;
 
}

.category-box {
width: 100%;
      height: 130px;

        background: white;
    border: 1px solid #D2D2D2;
    border-radius: 5px;
 padding: 10px;
 
}
