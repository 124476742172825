.G-salary-calculation-date-block {
  margin-top: 18px;
  display: flex;
  align-items: flex-start;
  margin-right: 25px;
  p {
    margin-right: 10px;
  }
  button {
    background: none;
    cursor: pointer;
    border: 1px solid rgb(46, 43, 43);
    padding: 6px 6px;
    border-radius: 5px;
    font-size: 12px;
    transition: 0.3s;
    &:hover {
      box-shadow: inset 0px 4px 4px rgba(0, 0, 0, 0.25);
    }
  }
}
.G-salary-calculation-date-form {
  max-width: 400px;
  width: 100%;
  .G-confirm-btn-wrap {
    max-width: 100px;
    width: 100%;
  }
}
