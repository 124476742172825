@import '../../Assets/Scss/mixin.scss';
// dashboard global styling start

.L-dashboard-main-block {
  .L-dashboard-boxes-parent {
    margin: -23px;

    .L-dashboard-warper {
      width: 50%;
      padding: 23px;

      .G-dashboard-title {
        h3 {
          font-size: 16px;
          line-height: 21px;
          color: #0e2048;
          font-weight: 700;
        }

        img {
          width: 22px;
          margin-left: 15px;
        }
      }

      .G-block-style {
        width: 100%;
        padding: 15px;
        height: 300px;
        border: 1px solid #571845;
        border-radius: 4px;
        background-color: #fff;
        box-shadow: 0 19px 38px rgba(0, 0, 0, 0.3),
          0 15px 12px rgba(0, 0, 0, 0.22);
      }

      .L-exchange-rate {
        max-width: 510px;
        width: 100%;
      }
    }
  }

  .G-dashboard-calendar {
    margin-left: 15px;
  }
}

.L-dashboard-tables {
  width: 100%;

  .G-table-header {
    background: #eeee;

    .G-table-header-cell {
      color: black !important;
    }
  }

  .G-table-body {
    @include tableScrollBar;

    .G-table-body-row {
      background: #ffffff;

      &:nth-child(even) {
        background: #eeee;
      }
    }
  }
}

// dashboard global styling end

// L-partner-balance start
.L-partner-balance {
  .G-table-body {
    .G-table-body-cell {
      &:nth-child(3),
      &:nth-child(4),
      &:nth-child(5),
      &:nth-child(6) {
        justify-content: flex-end;
      }
    }
    .G-table-body-cell {
      &:nth-child(2) {
        justify-content: center;
      }
    }
  }
}

// L-partner-balance end

// L-financial-means start

.L-financial-means {
  .G-table-body {
    .G-table-body-cell {
      &:nth-child(2),
      &:nth-child(3) {
        overflow: hidden;
      }
    }
  }

  .L-financial-means-content {
    width: 100%;

    .L-financial-means-tabs {
      margin-top: 15px;

      .L-tab-wrapper {
        max-width: 130px;
        width: 100%;
        &:last-child {
          margin-left: 25px;
        }

        > button {
          background-color: rgba(34, 9, 27, 0.2);
          border: 1px solid rgba(34, 9, 27, 0.2);
        }
      }

      .L-active-tab {
        > button {
          border-radius: 4px !important;
          background: #fff;

          p {
            color: #571845;
          }
        }
      }
    }
  }
}

.L-cash-registers-table {
  .G-table-body {
    max-height: calc(32 * 4px);

    .G-table-body-cell {
      &:last-child {
        justify-content: flex-end;
      }
    }

    .G-table-body-cell {
      &:nth-child(4) {
        justify-content: center;
      }
    }
  }
}

// L-financial-means end

//L-exchange-rate start

.L-exchange-rate {
  .L-exchange-rate-table {
    .G-table-body {
      @include tableScrollBar;
      max-height: 193px;

      .G-table-body-cell {
        &:nth-child(2),
        &:nth-child(3) {
          justify-content: flex-end;
        }
      }
    }
  }

  .G-table-header {
    display: none;
  }

  .L-rate-increase-decrease {
    .L-rate-level-up {
      svg {
        width: 10px;
        height: 10px;
      }
    }

    .L-rate-level-down {
      svg {
        transform: rotate(-180deg);

        path {
          fill: blue;
        }
      }
    }

    > p {
      margin-left: 15px;
    }
  }
}

//L-exchange-rate end

//L-unsigned-account-box start
.L-unsigned-account-box {
  margin-top: 50px;

  .L-unsigned-icon {
    img {
      width: 32px;
      height: 32px;
      cursor: pointer;
    }
  }

  .L-unsigned-account-title-count {
    margin-left: 20px;

    span,
    p {
      font-size: 16px;
      line-height: 18px;
      color: #000;
    }
  }
}

.G-dashboard-canceled-btn {
  max-width: 125px;
  width: 100%;
}
.L-dashboard-wrap {
  .G-header-bot-area {
    justify-content: flex-start;
  }
}
.L-currency-tracker {
  background-color: #e9e6e6 !important;

  .recharts-default-legend {
    .recharts-surface {
      display: none !important;
    }
  }
  .recharts-legend-wrapper {
    right: 8px !important;
  }
  .recharts-legend-item {
    margin-top: 10px;
    &:last-child {
      margin-bottom: 26px;
    }
  }
  .recharts-legend-item-text {
    font-weight: bold !important;
  }
  .recharts-legend-item {
    width: 20px;
    span {
      font-size: 14px;
      font-weight: 400;
    }
  }
  .recharts-tooltip-item {
    .recharts-tooltip-item-name,
    .recharts-tooltip-item-value {
      font-size: 14px;
    }
  }
  .yAxis {
    display: none;
  }
}
//modal style start

//modal style end

.recharts-text,
.recharts-cartesian-axis-tick-value {
  font-size: 12px;
  font-weight: 600;
}
