.date-wrap {
  input {
    max-width: 140px;
    width: 100%;

    border: 1px solid #1d5d90;
    border-radius: 4px;
    padding: 5px 8px;
    font-size: 13px !important;
  }
}
