.L-assign-positions-table {
  .G-table-body-cell {
    &:nth-child(7) {
      justify-content: end;
    }
  }
  .G-numeric-format-filed {
    input {
      border: none;
      height: 100%;
      background: transparent;
    }
  }
  .row-has-red-bg {
    .G-table-body-cell,
    input {
      color: black !important;
    }
  }
  .react-datepicker__input-container {
    input {
      width: 100%;
      height: 30px;
      border: none;
    }
  }
}

.L-assign-positions-mess {
  font-size: 14px;
  text-align: center;
  margin-top: 10px;
}

.is-bold {
  font-weight: bold;
}

.error-tooltip {
  opacity: 0;
  transition: 0.3s;

  &:hover {
    opacity: 1;
  }
}
