.G-toolbar-block {
  .open-icon {
    display: flex;
    cursor: pointer;

    > svg {
      width: 32px;
      height: 32px;
      fill: black;
    }
  }

  .P-toolbar-list {
    width: 160px;

    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    top: 33px;
    z-index: 99;

    .G-toolbar-block {
      border: 1px solid #7f5b5b;
      background: #f2f7f7;

      li {
        padding: 4px 8px;
        cursor: pointer;
        transition: 0.3s;
        font-size: 14px;
        line-height: 24px;
        display: flex;
        justify-content: flex-start;
        align-items: center;
        color: #1e1515;

        &:hover {
          background: #55132a;
          color: #fff;
        }
      }
    }
  }
}
