@import '../../Assets/Scss/mixin.scss';

.G-search-bg {
  margin-top: 40px;
  background-color: #c7a2a9;
  border-radius: 4px;
  padding: 8px 10px;
}

//G-advance-search-btn start
.G-advance-search-btn {
  @include largeBtn;
  position: relative;

  .L-icon {
    position: absolute;
    right: 12px;
    top: 8px;
    cursor: pointer;
    display: flex;

    img {
      width: 14px;
      height: 14px;
    }

    .G-icon-rotate {
      transform: rotate(180deg);
    }
  }
}

// G search input style
.G-search-input-wrapper {
  max-width: 300px;
  width: 100%;
  position: relative;
  margin: 0 auto;

  label {
    width: 100%;

    input {
      background: #fff;
      border: 1px solid #8b8b8b;
      padding: 8px 5px 8px 25px;
      transition: 0.3s;
      border-radius: 8px;
      width: 100%;
      font-size: 12px;
      &::placeholder {
        font-size: 13px;
      }
    }
  }

  .G-search-icon {
    position: absolute;
    left: 10px;

    bottom: 9px;
    display: flex;

    svg {
      width: 14px;
    }
  }
}

.G-search-form-input {
  width: 100%;
}
