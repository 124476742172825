.L-small-btn {
    max-width: 30px;
    width: 100%;
    height: 30px;

    .G-button {
        background: none;
        border: none;

        &:hover {
            box-shadow: none;
        }

        P {
            color: black;
            font-weight: bold;
        }
    }


    &:nth-child(2) {
        margin: 0 10px;
    }
}

.isActive {
    border-radius: 50%;
    border: 1px solid black;
    display: flex;
    justify-content: center;
    align-items: center;
}

.L-small-btn {
    &:first-child {
        >button {
            p {
                font-size: 22px !important;
            }
        }

    }

    &:nth-child(2) {
        >button {
            p {
                font-size: 16px !important;
            }
        }

    }

    &:last-child {
        >button {
            p {
                font-size: 14px !important;
            }
        }

    }
}

.dynamic-font-size-block {
    width: 305px;
    align-items: center;
}