@import "../../Assets/Scss/mixin.scss";

.L-close-account-action-block {
  align-items: center;
>.G-form-data-picker {
    width: 150px;
  }
  .G-account-wrapper {
    max-width: 400px;
    width: 100%;
  }
.G-view-btn {
  max-width: 184px;

}

.G-view-btn {
  &:last-child {
    max-width: 125px;
  }
}
}

.L-closing-account-balance-table {
  .G-table-body-cell {

    &:nth-child(3),
    &:nth-child(4) {
      justify-content: flex-end;
    }
  }
}
.L-accounts-closed-reporting {
  max-width: 600px;
  width: 100%;
  .scrollable{
  min-height: 40vh;
  }

  .L-date-formulation {
    max-width: 210px;
    width: 100%;
  }

  .G-view-btn {
    margin-top: 20px;
    margin-right: 0;
    margin-left: 10px;
  }
}
.L-accounts-closed-reporting{
 
  

  .G-view-btn{
    max-width: 90px !important;
  }
  .G-button {
    p{
      font-size: 14px;
    }
  }
  .G-search-bg{
    padding: 0;
  }
}

.L-closed-reporting-table{
   .G-table-body {
    @include tableScrollBar;
    max-height: calc(32 * 8px);
  }
}
