.dropdown-block {
  input {
    color: white !important;
    font-size: 12px !important;
  }

  .css-14el2xx-placeholder {
    font-size: 12px !important;
    color: white;
  }

  .css-1s2u09g-control {
    background-color: #1f7047;
    cursor: pointer;
    border-radius: 5px;
  }
  .css-qc6sy-singleValue {
    color: white;
  }

  .css-1pahdxg-control {
    background-color: #1f7047;
    border-color: #1f7047;
    &:hover {
      background-color: #1f7047;
    }
  }
}
