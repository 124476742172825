.position-form-block {
  max-width: 500px;
  width: 100%;
  .input-container {
    max-width: 100%;
    &:first-child {
      max-width: 40%;
    }
  }
}
