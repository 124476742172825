.selectControl {
  position: relative;
  width: 100%;
}

.selectControlWrapper {
  display: flex;

  span {
    font-size: 18px;
    color: red;
  }
}

.selectControlLabel {
  position: absolute;
  top: 50%;
  display: flex;
  align-items: center;
  // padding: 4px 12px;
  transform: translateY(-50%);
  cursor: text;
  transition: all ease-in-out 0.1s;

  &.atTop {
    top: 0;
    font-size: 12px;
    transform: translateY(0);
  }

  span {
    pointer-events: none;
  }

  .css-1s2u09g-control {
    border-bottom-left-radius: 4px;
    border: 1px solid #737373;
    border-top: none;
    border-right: none;
    background-color: red !important;
  }
}

.selectControlTooltip {
  margin-left: 8px;
}

.wrapperLoader {
  margin-top: 10px;
}

.skeleton {
  height: 8px;
  border-radius: 4px;
}

.css-qc6sy-singleValue {
  > input {
    font-size: 14px !important;
  }
}
