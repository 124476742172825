.saving-form-block {
  max-width: 500px;
  width: 100%;
  .G-modal-body {
    max-height: 80vh;
    overflow-y: unset;
  }
  .saving-input-container {
    width: 100%;
    margin-top: 10px;
    &:first-child {
      width: 30%;
    }
  }
  .select-container {
    max-width: 100%;
    padding: 0;
    margin-top: 10px;
  }
}
.G-checkbox-wrapper {
  margin-top: 10px;
}
.saving-account-block {
  width: 100%;
  display: flex;
  justify-content: space-between;
  margin-top: 10px;
  .G-s-input-wrap {
    width: 50%;
    &:last-child {
      margin-left: 15px;
    }
  }
}

.OnHoldSavingList {
  max-height: 200px;
  overflow-y: auto;
  border: 1px solid rgb(189, 172, 172);
  border-radius: 3px;
}
