.L-warehouse-form {
  max-width: 500px;
  width: 100%;
  .L-warehouse-input {
    margin-top: 10px;
    width: 100%;
    position: relative;
    &:first-child {
      width: 30%;
      margin-top: 0;
    }
  }
}
