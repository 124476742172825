.G-organization-form {
  width: 100%;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  .G-button {
    background: #463838 !important;
    border: 1px solid #463838 !important;
  }
}
.G-organization-form-content {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  margin: -8px -10px;
  .G-input-block {
    border: 1px solid black !important;
    border-top: none !important;
    border-right: none !important;
  }
}
.G-organization-form-field {
  width: 33.33%;
  padding: 8px 10px;
  .radio-btn-flex {
    border: 1px solid black;
    padding: 10px 5px;
    border-radius: 3px;
  }
  &:nth-child(13) {
    .G-radio-but-wrap {
      &:last-child {
        margin-left: 18px;
      }
    }
  }
}

.G-organization-submit-form {
  width: 100%;
  display: flex;
  justify-content: flex-end;
  .G-organization-submit-form-btn {
    max-width: 100px;
    width: 100%;
  }
}
