.L-payment-order-form {
  max-width: 720px;
  width: 100%;


  .L-payment-order-radio-btn-flex {
    display: flex;
    flex-direction: column;
    margin-top: 2px;
    margin-left: 9px;

    .G-radio-button {
      padding-top: 4px;
    }
  }
  .L-payment-order-form-content {
    width: 100%;

    .L-payment-order-header {
      margin-top: 15px;
      display: flex;

      .G-form-data-picker,
      .G-currency-wrapper {
        margin-left: 15px;
      }
    }

    .L-payment-order-panel-flex {
      margin: 10px -10px 0 -10px;

      .L-payment-order-panel {
        width: 50%;
        padding: 10px 10px 0 10px;

        .G-amount-input-wrap {
          width: 50%;
        }

        .L-payment-order-wrapper {
          margin-top: 10px;
          position: relative;

          .G-account-wrapper,
          .G-bank-wrapper,
          .G-partner-wrapper {
            max-width: 100%;
            width: 100%;
          }
        }

        .G-taxCode-input-filed {
          margin-top: 12px;
        }
      }
    }
  }
}

.L-payment-order-table {
  .G-table-body-cell {
    &:nth-child(2) {
      justify-content: center;
    }
    &:nth-child(5) {
      justify-content: flex-end;
    }
  }
}

.L-po-partner-flex {
  .G-add-icon {
    margin-top: 24px;
  }

  .Tooltip-Tip.top {
    top: 0;
    left: -34px;
  }
}

.L-payment-checkBox {
  .G-checkbox-wrapper {
    margin-left: 15px;
  }
}
.L-payment-order-panel {
  &:first-child {
    .L-payment-order-panel-content {
      margin-top: 14px;
      .G-available-amount {
        margin-left: 10px;
      }
    }
  }
}
.L-taxNumber-filed {
  max-width: 160px;
  margin-left: 90px;
  .G-input-wrapper {
    width: 30%;
  }
  .G-input-block {
    height: 30px;
  }
}

.L-available-amount-block{
margin-top: 13px;
    margin-left: 18px;
}