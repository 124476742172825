.selection-option-block {
  max-width: 300px;
  width: 100%;
  margin-top: 15px;
  padding: 10px;
 
}

.option-type-wrap {
  display: flex;
  align-items: center;

margin-top: 3px;


}
// SelectionOptions.scss
 

.container {
  display: block;
  position: relative;
  padding-left: 30px;
    padding-top: 12px
;
  cursor: pointer;
 font-size: 13px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

/* Hide the browser's default checkbox */
.container input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}

/* Create a custom checkbox */
.checkmark {
  position: absolute;
  top: 0;
  left: 0;
  height: 25px;
  width: 25px;
  border-radius: 50%;
  background: var(--typeColor); 
     margin: 8px 0;
}


/* When the checkbox is checked, add a blue background */
.container input:checked ~ .checkmark {
    background: var(--typeColor); 
}

/* Create the checkmark/indicator (hidden when not checked) */
.checkmark:after {
  content: "";
  position: absolute;
  display: none;
}

/* Show the checkmark when checked */
.container input:checked ~ .checkmark:after {
  display: block;
}

/* Style the checkmark/indicator */
.container .checkmark:after {
  left: 9px;
  top: 5px;
  width: 5px;
  height: 10px;
  border: solid white;
  border-width: 0 3px 3px 0;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
}