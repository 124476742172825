.other-calendar-block {
  


  .L-other-calendar-block {
    margin-top: 3px;
  }


  .L-other-calendar-type {
    font-size: 13px;
  }
}

.calendar-title {
  font-size: 14px;
  font-weight: bold;
  margin-bottom: 6px;
}


.other-calendar-block {
  cursor: pointer;
  margin-left: 13px;
  .L-calendar-checkbox-wrap {
    margin-top: 4px;
    
  &:nth-child(1) {
        margin-top: 15px;
      }

     input{
        cursor: pointer;
      } 
  }

 
}