.L-menu-block {
  width: 4%;
  height: 100vh;
  position: fixed;
  left: 0;
  top: 0;
  background: #55132a;
  transition: 0.3s;
  z-index: 5;

  .L-open-close-block {
    position: relative;
    z-index: 88;

    .L-open-close-icon {
      position: absolute;
      right: -20px;
      top: 60px;
      height: 40px;
      width: 40px;
      border-radius: 50%;
      background: #ffffff;
      display: flex;
      justify-content: center;
      align-items: center;
      cursor: pointer;

      span {
        display: flex;
      }
    }
  }

  .L-menu-content {
    display: flex;
    flex-direction: column;

    .pl {
      padding-left: 0 !important;
    }

    .L-logo-block {
      padding-top: 36px;
      padding-left: 23px;
      cursor: pointer;

      .L-logo-flex {
        display: flex;
        align-items: center;
      }

      h3 {
        font-weight: 500;
        font-size: 18px;
        line-height: 24px;
        letter-spacing: 3px;
        color: #ffffff;
        margin-left: 5px;
      }
    }

    .L-menu-list {
      margin-top: 45px;
      width: 100%;

      ul {
        .L-menu-item {
          display: flex;
          align-items: center;
          transition: 0.3s;
          margin-top: 15px;
          position: relative;

          &:first-child {
            margin-top: 0;
          }

          > a {
            padding: 9px 5px;
            display: flex;
            width: 100%;
            justify-content: center;
          }

          .L-menu-children {
            min-width: 300px;
            position: absolute;
            left: 100%;
            top: -1px;

            background: #b1606e;
            border: 1px solid #b1606e;
            border-radius: 0px 6px 0px 0px;

            display: none;

            .G-menu-title {
              font-size: 14px;
              color: white;
              padding: 5px 24px;
              white-space: nowrap;
            }

            > ul {
              max-height: calc(29 * 7px);
              overflow-y: auto;

              &::-webkit-scrollbar {
                width: 7px;
                height: 8px;
                background: #a9889e;
                border-radius: 100px;
              }

              &::-webkit-scrollbar-thumb {
                background-color: #858a95;
                width: 8px;
                -webkit-border-radius: 10px;
                border-radius: 10px;
                cursor: pointer;
              }
            }

            li {
              padding: 5px 24px;
              transition: 0.3s;
              cursor: pointer;
              white-space: nowrap;

              a {
                font-size: 14px;
                line-height: 18px;
                letter-spacing: 0.05em;
                color: white;
                transition: 0.3s;
              }

              &:hover {
                background: #55132a;
              }

              &:hover {
                a {
                  color: #ffffff;
                }
              }
            }
          }

          &:hover {
            .L-menu-children {
              display: block;
            }
          }

          &::before {
            content: '';
            position: absolute;
            left: 0;
            top: 0;
            width: 9px;
            height: 100%;
            background: #bb3b50;
            border-radius: 0px 10px 10px 0px;
            display: none;
            transform: 0.3s;
          }

          &:hover {
            background: #eee9;

            span {
              color: #000;
            }

            &:before {
              display: block;
            }
          }

          img {
            width: 30px;

            svg {
              fill: red;
            }
          }

          a {
            span {
              margin-left: 20px;
              font-size: 15px;
              line-height: 23px;
              color: #ffffff;
              margin-top: 2px;
            }
          }
        }
      }
    }
  }
}

.L-active-menu {
  width: 20%;

  .L-menu-item {
    > a {
      justify-content: unset !important;
      margin-left: 20px;
    }
  }

  .L-menu-children {
    left: 100%;
  }
}

.hidden {
  display: none;
}

.L-rotate-icon {
  transform: rotate(180deg);
}
