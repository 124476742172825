.G-modal-block {
  max-width: 800px;
  width: 100%;
  background: #fff;
  border: 1px solid #000;
  border-radius: 4px;
  // position: absolute;
  // transform: translate(-50%, -50%);
  z-index: 999;
  box-shadow: 0 19px 38px rgba(0, 0, 0, 0.3), 0 15px 12px rgba(0, 0, 0, 0.22);
  padding-right: 4px;
}

.move {
  width: 30px;
  height: 30px;
  background: #a98ca1;
  cursor: pointer;
  border-radius: 50%;
  // background-image: url('../../Assets/icons/move.png');
  background-size: cover;
  background-repeat: no-repeat;
}
.G-modal-header {
  position: relative;
  display: flex;

  align-items: center;
  .G-modal-title {
    margin: 0 auto;
    font-size: 16px;
    font-weight: 100;
  }
}

.G-modal-header,
.G-modal-body {
  padding: 10px 20px;
}

.scrollable {
  max-height: 80vh;

  overflow-y: auto;
  &::-webkit-scrollbar {
    width: 7px;
    height: 8px;
    background: #f5f5f5;
    border-radius: 100px;
  }

  &::-webkit-scrollbar-thumb {
    background-color: #858a95;
    width: 8px;
    -webkit-border-radius: 10px;
    border-radius: 10px;
    cursor: pointer;
  }
}
