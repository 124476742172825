.L-dashboard-employees-list {
  max-width: 760px;
  width: 100%;
  border: 1px solid rgba(0, 0, 0, 0.459);
  border-radius: 5px;
  padding: 20px;
  box-shadow: 0 19px 38px rgba(0, 0, 0, 0.3), 0 15px 12px rgba(0, 0, 0, 0.22);
}
.L-employee-table-list {
  .G-table-body {
    display: block;
    max-height: 191px;
    overflow-y: auto;
    .G-table-body-cell {
      overflow: hidden;
    }
    .G-table-body-cell {
      &:last-child {
        justify-content: end;
      }
    }
  }
}

.L-employees-count-date {
  display: flex;
}

.L-employees-list-total {
  display: flex;
  justify-content: flex-end;
  margin-top: 20px;
  h4 {
    margin-right: 90px;
    font-size: 14px;
    font-weight: 100;
  }
  span {
    font-size: 12px;
  }
}
