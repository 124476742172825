.L-reporting-period-wrapper {
  max-width: 400px;
  width: 100%;
  border: 1px solid #eee;
  padding: 10px;
  margin-top: 15px;
  .L-reporting-period-btn-flex {
      display: flex;
      justify-content: flex-end;
      margin-top: 20px;
  
      .G-register-btn {
  
        margin-left: 10px;
      }
    }
  
  .G-form-data-picker {
    width: 215px;
    margin-bottom: 10px;
  }

  .L-take-count-input {
    max-width: 200px;
    width: 100%;
    position: relative;

    .G-input-block {
      width: 80px;
    }
  }
}

.L-reporting-period-flex {
  justify-content: space-between;

  .G-help-btn {
    margin-left: 15px;
  }
}