.button-wrap {
  max-width: 80px;
  width: 100%;
  button {
    width: 100%;
    height: 32px;
    background: #1F7047;
    border-color: #1F7047;
    cursor: pointer;
    border-radius: 5px;
    transform: 0.3s;
    p {
      font-size: 15px;
      line-height: 16px;
      color: white;
    }
  }
}
