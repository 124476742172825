@import '../../Assets/Scss/mixin.scss';

.L-EInvoicing-operations-table {
  .L-EInvoicing-operations-circle {
    display: flex;
    margin: 0 auto;

    svg {
      width: 24px;
      height: 24px;
      fill: #2ecc71;
    }
  }

  .G-table-body-row {
    .G-table-body-cell {
      &:nth-child(2) {
        @include whiteSpaceNowrap;
      }
    }
  }

  .G-table-body-cell {
    &:nth-child(3),
    &:nth-child(5),
    &:nth-child(6),
    &:nth-child(7) {
      justify-content: flex-end;
    }
  }
}

.L-eInvoicing-formulations-type-block {
  display: flex;
  align-items: center;

  .L-invoicing-formulations-type-box {
    margin-left: 15px;
  }
}

.L-operations-modal {
  .L-operations-wrapper {
    width: 100%;
    margin: 15px 0;

    .L-operations-panel {
      &:first-child {
        width: 30%;
      }
      h3 {
        font-size: 15px;
        padding: 12px 0;

        font-weight: 100;
        .G-asterisk {
          padding-right: 5px;
          padding-top: 0;
        }
      }
      &:last-child {
        width: 70%;
      }

      > p {
        font-size: 14px;
        padding: 12px 0;
      }

      .G-form-data-picker,
      .G-amount-input,
      .L-operations-account {
        margin-top: 12px;
      }
    }
  }
}

.G-border-style {
  width: 100%;
  border-top: 1px solid black;
}

.L-operations-modal {
  max-width: 1200px;
  width: 100%;

  .G-small-btn {
    margin-top: 15px;
  }
}

.L-sign-modal {
  max-width: 400px;
  width: 100%;
}

.L-bank-entry-order-modal {
  max-width: 600px;
  width: 100%;

  .L-bank-entry-wrapper,
  .G-amount-input {
    margin-top: 10px;
  }

  > p {
    font-size: 16px;
  }
}

.L-payment-order-modal {
  max-width: 900px;
  width: 100%;

  .L-payment-order-header {
    .G-docNum-input-not-border {
      margin-left: 10px;
    }
  }

  .L-payment-form {
    margin: 15px -10px 0;

    .L-payment-form-panel {
      width: 50%;
      padding: 0 10px;
      margin-top: 20px;

      .L-payment-order-select-wrapper {
        max-width: 100%;
        margin-top: 10px;
      }

      .G-amount-input {
        margin-top: 10px;
      }
    }

    > p {
      font-size: 16px;
      margin-top: 3px;
    }
  }
}

.L-EInvoicing-operations-flex {
  display: flex;

  .G-calendar-box-flex {
    margin-left: 0;
  }
}
.L-EInvoicing-operations-table {
  .P-toolbar-list {
    width: 200px !important;
  }
}

.L-operation-data-table {
  .G-table-body-cell {
    &:nth-child(4),
    &:nth-child(5),
    &:nth-child(6),
    &:nth-child(7),
    &:nth-child(8),
    &:nth-child(9),
    &:nth-child(10) {
      justify-content: flex-end;
    }

    &:last-child {
      justify-content: center;
    }
  }
}

.L-amount-flex {
  .G-amount-input {
    &:last-child {
      margin-left: 20px;
    }
  }
}

.L-warehouse-modal {
  max-width: 999px;
  width: 100%;
  .L-warehouse-details-header {
    width: 80%;
    .list-item {
      padding: 8px 0;
      border-bottom: 2px solid #ddd;
      display: flex;
      justify-content: flex-start;
      font-size: 14px;
      &:last-child {
        border-bottom: none;
      }
      .label {
        font-weight: bold;
        color: #555;
      }
      .value {
        color: #000;
        font-size: 12px;
        display: block;
        margin-left: 15px;
      }
    }
  }
}
