.L-calculation-sheet-action-box {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  .L-calculation-sheet-filed {
    max-width: 200px;
    width: 100%;
    &:nth-child(1) {
      max-width: 160px;
      width: 100%;
    }
    &:nth-child(3) {
      max-width: 280px;
      width: 100%;
    }
  }
}

.G-calculation-total {
  width: 100%;
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
  flex-direction: column;
  .G-calculation-total-box {
    max-width: 400px;
    width: 100%;
    display: flex;
    margin-top: 10px;
    &:nth-child(2) {
      p {
        text-align: start;
      }
    }
    h4 {
      font-size: 14px;
      font-weight: 100;
      margin-right: 20px;
    }
    p {
      max-width: 150px;
      width: 100%;
      font-size: 13px;
      margin-right: 10px;
      text-align: end;
    }
  }
}

.L-calculation-sheets-table {
  .G-table-body-cell {
    &:nth-child(n + 3) {
      justify-content: end;
    }
  }
}
