.salary-main-container {
  width: 96%;
  height: 100vh;
  margin-left: 4%;
}

.salary-logo-block {
  display: flex;
  align-items: center;
  padding-top: 10px;
  padding-left: 40px;
  h3 {
    font-size: 18px;
    margin-left: 5px;
    font-weight: 700;
  }
}
.salary-logo-wrap {
  width: 50px;
  height: 50px;
  display: flex;
  img {
    width: 100%;
    height: 100%;
  }
}
