.full-calendar-wrap {
  width: 100%;
}

.L-full-calendar-block{
  margin-top: 25px;
}


.s-inp {
  padding: 20px 2px;
  input {
    border: 1px solid black;
    height: 32px;
    padding: 2px;
    border-radius: 3px;
  }
}

.full-calendar-wrap-left-panel {
  width: 20%;

  padding: 10px 15px;
}

.full-calendar-wrap-center-panel {
  width: 60%;
}

.full-calendar-wrap-right-panel {
  width: 20%;

  padding: 10px 15px;
}


