.provisions-group {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  margin: -15px -10px;
}

.G-provisions-table {
  .G-table-body-cell {
    &:nth-child(3) {
      justify-content: center;
    }
  }
  .G-table-body-cell {
    &:nth-child(1),
    &:nth-child(2),
    &:nth-child(4) {
      justify-content: flex-end;
    }
  }
}
