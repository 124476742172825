.G-total-list-block {
  width: 100%;
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
  margin: 30px 0;

  h4 {
    width: 100px;
    font-weight: 300;
  }

  strong {
    max-width: 150px;
    width: 100%;
    text-align: right;
    font-weight: 200;
    font-size: 13px;

  }
}