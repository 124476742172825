.L-balance-book-table {
  .G-table-body-cell {
    &:nth-child(2),
    &:nth-child(3),
    &:nth-child(4),
    &:nth-child(5) {
      justify-content: flex-end;
    }

    &:nth-child(6) {
      justify-content: center;
    }
  }
}

.partnerAccText {
  width: 100%;
  font-size: 12px;
  color: black;
  opacity: 0;
  transition: 0.3s;

  &:hover {
    opacity: 1;
  }
}
