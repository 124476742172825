.fc .fc-popover {
  max-width: 250px;
  width: 100%;
  max-height: 200px;
  overflow-y: auto;
  background: #e7d9d9;
}

.fc {
  box-shadow: 0 19px 38px rgba(0, 0, 0, 0.3), 0 15px 12px rgba(0, 0, 0, 0.22);
  background-color: hsl(0deg 56.97% 96.34% / 93.33%);
}

.fc-theme-standard td,
.fc-theme-standard th {
  border: 1px solid #d0b2b2;
}

.fc .fc-daygrid-day.fc-day-today {
  background-color:#e6cfcf;
}

.fc .fc-toolbar-title {
  font-size: 22px;
}

.full-calendar-block {
  width: 100%;

  .fc-header-toolbar button {
    background-color: #571845;
    color: white;
  }

  .fc-header-toolbar button {
    &:active {
      background-color: #50c878;
    }
  }
}


#react-select-3-listbox,
#react-select-5-listbox {
  z-index: 99;
}


.fc-button-group{
  > button{
    &:hover{
      background-color: #360929 !important;
    }
  }
.fc-button-active{
  background-color:  #360929 !important;
  border:  #360929;

}

}