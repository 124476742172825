.L-settlement-document-table {
  .G-table-body-cell {
    &:nth-child(3),
    &:nth-child(4),
    &:nth-child(5),
    &:nth-child(6) {
      justify-content: flex-end;
    }
  }
}
