.L-forgot-password-modal {
    max-width: 450px;
    width: 100%;

    .L-forgot-password-modal-content {
        width: 100%;

        >h3 {
            text-align: center;
            font-size: 16px;
            font-weight: 400;
            line-height: 20px;
        }

        .G-cancel-print-btn-block {
            justify-content: center;
            align-items: center;
        }
    }
}