.L-invoice-action {
  .G-partner-wrapper {
    margin-left: 35px;
  }

  .G-currency-wrapper {
    margin-left: 10px;
  }
}

.L-invoice-form-wrapper {
  max-width: 1200px;
  width: 100%;
}

.L-invoice-goods-form {
  max-width: 500px;

  .L-product-wrap {
    .G-add-icon {
      margin-top: 24px;
    }
  }
}

.L-invoice-goods-select-wrap {
  width: 100%;
  .L-invoice-prod-box {
    width: 90%;
  }
  .Tooltip-Wrapper {
    width: 10%;
    .G-add-icon {
      margin-top: 24px;
    }
    .Tooltip-Tip.top {
      top: -8px;
      left: -34px;
    }
  }
}

.L-invoice-goods-input-wrap {
  width: 50%;
}

.L-invoice-goods-select-wrap,
.L-invoice-goods-input-wrap {
  position: relative;
  margin-bottom: 10px;
}

.L-invoice-total-data-block {
  display: flex;
  justify-content: space-between;
  margin: 20px 0;

  .L-invoice-select-data-wrapper {
    max-width: 810px;
    width: 100%;

    .L-invoice-select-data-box {
      max-width: 440px;
      width: 100%;

      &:last-child {
        max-width: 365px;
        width: 100%;
        margin-left: 10px;
      }

      .G-bank-wrapper {
        max-width: 90%;
        margin-top: 10px;
      }

      .L-invoice-select-value {
        margin-top: 25px;
&:last-child{
      margin-top: 55px;
}
        span {
          font-size: 14px;
          color: black;
          line-height: 12px;
          margin-top: 10px;
        }
      }
    }
  }

  .L-invoice-total-wrapper {
    max-width: 340px;
    width: 100%;

    .L-invoice-total-box {
      max-width: 600px;
      width: 100%;
      display: flex;
      justify-content: space-between;
    }

    .L-invoice-select-value {
      margin-top: 10px;
    }

    .L-invoice-prePayment-input {
      max-width: 150px;
      margin-top: 10px;
    }
  }
}

.L-invoice-compilation-date-block {
  align-items: center;

  .L-invoice-compilation-input {
    max-width: 150px;
    width: 100%;
  }

  .G-form-data-picker {
    margin-left: 15px;
  }

  .G-modal-partner-name {
    margin-left: 25px;
  }
}
.L-invoice-table {
  .G-table-body-cell {
    &:nth-child(5),
    &:nth-child(6),
    &:nth-child(7),
    &:nth-child(8),
    &:nth-child(9) {
      justify-content: flex-end;
    }
  }
    .G-table-body-cell {
    &:nth-child(4) {
      justify-content:center;
    }
  }
}

.L-invoice-goods-table {
  .G-table-body-cell {
    &:nth-child(4),
    &:nth-child(5) {
      justify-content: flex-end;
    }
  }
}

.L-invoice-partner-box {
  display: flex;
  .Tooltip-Wrapper {
    margin-top: 3px;
  }
}
