.L-old-EInvoicing-upload{
    margin-top: 100px;
    .G-header-company-name {
        display: none;
    }
}


  .L-invoicing-radio-btn {
    max-width: 290px;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    border: 1px solid #737373;
    margin-left: 5px;
    margin-right: 5px;
    padding: 0 15px;
    height: 32px;

  }