.accept-or-cancel-modal {
  max-width: 400px !important;
  height: 198px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.accept-or-cancel-content {
  width: 100%;
  padding: 20px;

  p {
    font-size: 14px;
    line-height: 24px;
    color: #000;
    font-weight: 400;
    text-align: center;
  }
}
.accept-or-cancel-btn-block {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 15px;

  .button-wrap {
    &:last-child {
      margin-left: 10px;
    }
    button {
      &:hover {
        background-color: green;
      }
    }
  }
}
