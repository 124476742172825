.L-chart-of-account-form {
  max-width: 500px;
  width: 100%;

  .L-chart-of-account-form-content {
    width: 100%;

    .L-chart-of-account-wrapper {
      width: 100%;
      margin-top: 10px;
      position: relative;
    }
  }
}

.L-chart-of-table {
  .G-table-body-cell {
    &:nth-child(4),
    &:nth-child(5),
    &:nth-child(6),
    &:nth-child(7),
    &:nth-child(8),
    &:nth-child(9) {
      justify-content: center;
    }
  }
}
.L-chart-of-account-accountName-block {
  .L-chart-of-account {
    width: 30%;
  }

  .L-chart-of-accountName {
    width: 70%;
    .G-input-wrapper {
      margin-top: 25px;
    }
    .G-input-block {
      border-left: none;
      border-bottom-left-radius: 0;

      &:hover {
        border-left: none;
        border-bottom-left-radius: 0;
      }
    }
  }
}


.select-option-comp{
  .label{
    color: black !important;
  }
}

.L-chart-of-account-wrapper{

  .label{
    color: #a39797;
  }
 
}