.work-with-employees-table {
  .P-toolbar-list {
    width: 255px;
  }
}

.main-b {
  margin-top: 25px;
}
.work-with-employees-search {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.work-with-employees-search-flex {
  display: flex;
  align-items: center;
  width: 100%;
  .select-container,
  .input-container,
  .G-checkbox-wrapper {
    max-width: 250px;
    width: 100%;
    margin-left: 10px;
    &:first-child {
      margin-left: 0;
    }
  }
  .G-checkbox-wrapper {
    max-width: 220px;
  }
  .input-container {
    &:nth-child(3) {
      max-width: 120px;
      width: 100%;
    }
  }
}
.L-salary-calculation-modal {
  max-width: 350px;
  width: 100%;
  .L-salary-calculation-list {
    margin-top: 10px;
    strong {
      font-size: 14px;
      font-weight: 400;
    }
    p {
      font-size: 13px;
      margin-top: 5px;
    }
  }
  .G-button {
    max-width: 120px;
    width: 100%;
    margin-top: 12px;
  }
}
.L-employees-count {
  display: flex;
  align-items: center;
  margin-top: 10px;
  p {
    font-size: 12px;
  }
  strong {
    margin-left: 10px;
    font-size: 12px;
    font-weight: 100;
    padding: 4px;
    border: 1px solid black;
    border-radius: 3px;
  }
}

.L-vacation-calculation-modal,
.L-benefit-calculation-modal {
  max-width: 850px;
  width: 100%;
  height: 550px;
  overflow-y: auto;
}

.work-with-employees-table {
  .G-table-body-cell {
    &:nth-child(8) {
      justify-content: end;
    }
  }
}

.L-confirm-employee-position-modal {
  max-width: 1000px;
  width: 100%;
  max-height: 550px;
  overflow-y: auto;
}

.L-make-additional-save-modal {
  max-width: 1200px;
  height: 500px;
  overflow-y: auto;
}
