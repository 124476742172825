.L-calendarType-modal{
    max-width: 600px;
    width: 100%;

    .L-calendarType-wrap{margin-top: 18px;}
}

.L-calendar-list{
   padding: 5px 0;
    strong{
     font-size: 16px;
     font-weight: 400;
      margin: 0 auto;
      color: black;
    }
    p{
        margin-top: 5px;
        font-size: 14px;
    }
}