.personal-budget-logo-wrap {
  max-width: 420px;
  width: 100%;
  display: flex;
  align-items: center;

  .personal-budget-logo-wrap-box {
    max-width: 60px;
    width: 100%;
    height: 52px;

    display: flex;

    img {
      width: 100%;
      height: 100%;
    }
  }

  h3 {
    font-size: 18px;
    font-weight: 100;
    margin-left: 19px;
  }
  .user-info {
    margin-left: 10px;
    font-size: 16px;
    color: black;
  }
}
