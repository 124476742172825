.G-pagination-block {
  width: 100%;
  height: 48px;
  background: #c7a2a9;
  border-radius: 4px;
  margin: 24px auto;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 56px 0 13px;

  .G-page-count {
    font-size: 16px;
    line-height: 16px;
    color: black;
  }

  .paginationBttns {
    display: flex;
    position: relative;
    li {
      &:first-child {
        margin-left: 0;
      }
    }
    a {
      width: 25px;
      height: 25px;
      display: flex;
      justify-content: center;
      align-items: center;
      font-size: 15px;
      line-height: 20px;
      color: #8b8b8b;
      transition: 0.3s;

      &:hover {
        color: #c98db8;
      }
    }

    li {
      margin: 0 5px;

      &:nth-child(2) {
        margin-left: 5px;
      }
      a {
        cursor: pointer;
      }
    }

    .previouseBttn {
      &:first-child {
        width: 10px;
        height: 10px;
        border: 1.8px solid #55132a;
        border-right: none;
        border-bottom: none;
        transform: rotate(-45deg);
        position: absolute;
        top: 8px;
        left: -20px;
      }
    }

    .nextBttn.paginationDisabled {
      width: 10px;
      height: 10px;
      border: 1.8px solid #571845;
      border-left: none;
      border-top: none;
      transform: rotate(-45deg);
      position: absolute;
      top: 8px;
      right: -20px;
      cursor: not-allowed;
    }

    .previouseBttn.paginationDisabled {
      width: 10px;
      height: 10px;
      border: 1.8px solid #571845;
      border-right: none;
      border-bottom: none;
      transform: rotate(-45deg);
      position: absolute;
      top: 8px;
      left: -20px;
      cursor: not-allowed;
    }

    .nextBttn {
      width: 10px;
      height: 10px;
      border: 1.8px solid #571845;
      border-left: none;
      border-top: none;
      transform: rotate(-45deg);
      position: absolute;
      top: 8px;
      right: -20px;
      cursor: pointer;
    }
  }

  .paginationActive {
    border: 1px solid #571845;
    border-radius: 4px;
    cursor: none !important;

    a {
      color: #571845;
    }
  }
}
