$MarginTop: 10px;
.L-event-remove {
  width: 25px;
  height: 25px;
  cursor: pointer;
  position: absolute;
    right: 7%;
    top: 6px;
  display: flex;
  img {
    width: 100%;
  }
}



.calendar-form {
  width: 100%;
}

.to-remind-wrap {
  align-items: center;
  margin-top: $MarginTop;
  height: 40px;
  .G-select-wrapper {
    width: 120px;
    margin: 0 10px;
    .css-1s2u09g-control {
      height: 33px !important;
    }
  }

  span {
    font-size: 14px;
  }
}

.calendar-form-input {
  margin-top: $MarginTop;
  .G-input-block {
    border-left: none !important;
    border-bottom-left-radius: 0 !important;
    border-bottom: 1px solid #e0e2e5 !important;
    &::placeholder {
      font-size: 12px !important;
    }
  }
}

//Start to remind style

.calendar-form-modal {
  max-width: 530px;
  width: 100%;

  .G-modal-block-title {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    .current-day {
      font-size: 14px;
    }
  }
}

.to-remind-time {
  .react-datepicker__input-container {
    input {
      width: 45px;
    }
  }
}

.clock-wrap {
  margin-top: $MarginTop;

   input{
      border: 1px solid black;
      width: 52px;
      border-radius: 3px;
      height: 24px;
      padding: 2px;
    }
  
}

.check-all-day{
    margin-top: $MarginTop;
    input{
      cursor: pointer;
    }
}

.L-calendar-form-action-btn-flex{
    display: flex;
    align-items: flex-end;
    justify-content: flex-end;
  margin-top: 20px;
  .calendar-remove-btn{
        max-width: 125px;
    width: 100%;

  }
  .G-cancel-print-btn-block {
    width: 60%;
    margin-top: 0;
  }
}