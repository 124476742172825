.L-non-working-days-modal {
  max-width: 450px;
  width: 100%;
}
.L-non-working-days-modal-content {
  .G-s-input-wrap {
    margin-top: 10px;
  }
  .G-radio-button {
    margin-left: 10px;
    &:first-child {
      margin-left: 0;
    }
  }
}

.L-non-working-days-block {
  .G-search-bg {
    display: flex;
    align-items: flex-end;
    justify-content: flex-end;
  }
  .G-button {
    max-width: 105px;
    width: 100%;
    margin-right: 0;
  }
}

.employees-radio-flex {
  .L-radio-btn-b {
    &:nth-child(2) {
      margin: 0 8px;
    }
  }
}
