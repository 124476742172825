.L-material-groups-form {
  max-width: 500px;
  width: 100%;
  .L-material-group-input {
    &:first-child {
      width: 30%;
      margin-top: 0;
    }
  }
  .L-material-group-dropdown,
  .L-material-group-input {
    width: 100%;
    margin-top: 10px;
    position: relative;
  }
}
