.L-input-has-border {
  @include inputBorderStyles;
}

.G-invalid-input {
  border: 1px solid red !important;
  background: #f1bbbb !important;
}

.G-valid-input {
  border: 1px solid green;
}

.G-relative {
  position: relative;
}
