.L-vacation-calculation-tab-content {
  padding: 20px 5px;
}
.L-general-vacation-form {
  form {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    margin: -10px;
  }
  .L-general-vacation-filed {
    width: 50%;
    padding: 10px;

    &:first-child {
      max-width: 250px;
      width: 100%;
    }

    &:nth-child(5),
    &:nth-child(7),
    &:nth-child(8),
    &:nth-child(9) {
      .G-s-input {
        max-width: 120px;
        width: 100%;
      }
    }
    .G-numeric-format-filed {
      input {
        max-width: 120px;
        width: 100%;
      }
    }
  }
  .G-salary-calender {
    max-width: 100%;
  }
}

.L-vacation-averages-table {
  input {
    width: 100%;
    height: 100%;
    background: transparent;
  }
  .G-numeric-format-filed {
    input {
      border: none;
      text-align: end;
    }
  }
}

.vacation-total-section {
  padding: 10px 5px;
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
  .vacation-total-label {
    font-size: 12px;
    font-weight: 100;
  }
  .vacation-total-value {
    max-width: 102px;
    width: 100%;
    font-size: 12px;
    text-align: end;
  }
}

.L-vacation-story-table {
  .G-table-body-cell {
    &:nth-child(8) {
      justify-content: flex-end;
    }
  }
}

.L-vacation-date-wrap {
  //max-width: 357px;
  // width: 100%;

  .G-salary-calender {
    max-width: 100px;
    width: 100%;
    &:last-child {
      margin-left: 10px;
    }
  }
}
.L-years-flex {
  width: 80%;
  border-bottom-left-radius: 4px;
  border: 1px solid #3c439c;
  border-top: none;
  border-right: none;
  padding: 9px 3px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  p {
    max-width: 150px;
    width: 100%;
    margin-bottom: 2px;
    margin-left: 10px;
    font-size: 13px;
    overflow: hidden;
  }

  .react-datepicker-wrapper {
    position: relative;
    max-width: 180px;
    width: 100%;
    &:nth-of-type(1)::after {
      content: '';
      position: absolute;
      width: 13px;
      height: 2px;
      background: black;
      top: 50%;

      transform: translate(-50%, -50%);
    }

    .react-datepicker__input-container {
      input {
        max-width: 120px;
        width: 100%;
        font-size: 14px;
        padding: 2px;
        border: none;
        font-weight: 600;
        background: transparent;
        cursor: pointer;
        text-align: center;
      }
    }
  }
}
.L-vacation-calculation-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 20px;
  .L-vacation-calculation-i {
    display: flex;
    align-items: center;
    h4 {
      font-weight: 100;
    }
    span {
      display: block;
      margin-left: 5px;
      font-size: 16px;
    }
  }
}
