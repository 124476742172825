.L-make-additional-save-action {
  .confirm-action-wrap {
    width: 90%;
    .input-container {
      margin-left: 10px;
    }
  }
}

.L-make-additional-save-table {
  input {
    width: 100%;
    height: 100% !important;
    border: none;
    background: transparent;
  }
  .G-numeric-format-filed {
    input {
      width: 100%;
      height: 100% !important;
      border: none;
      background: transparent;
    }
  }
  .G-table-body-cell {
    &:nth-child(1),
    &:nth-child(3) {
      justify-content: center;
    }
  }
}
