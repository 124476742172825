.L-warehouse-release-filter-block {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.L-warehouse-release-filter-flex {
  display: flex;

  width: 95%;

  .G-warehouse-release-filter-box {
    max-width: 250px;
    width: 100%;
    margin-left: 10px;
    &:nth-child(1) {
      max-width: 220px;
      width: 100%;
      margin-left: 0;
      .calendar-box {
        &:last-child {
          margin-left: 10px;
        }
      }
    }

    .G-select-control {
      width: 100%;
    }
    &:nth-child(5),
    &:nth-child(6),
    &:nth-child(7) {
      max-width: 180px;
      width: 100%;
    }
  }
}

.L-warehouse-release-entry-form {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  flex-wrap: wrap;
  margin: -5px -8px;
  .G-warehouse-release-entry-filed {
    width: 50%;
    padding: 5px 8px;
    &:nth-child(1),
    &:nth-child(2) {
      .G-material-value-input-wrap,
      .G-salary-calender {
        max-width: 200px;
        width: 100%;
      }
    }
  }
}
//start material release form
.L-material-release-form-table {
  max-width: 950px;
  width: 100%;
  .L-mat-stock-balance-table {
    margin-top: 20px;
  }
  .G-select-control {
    width: 50%;
  }
}

.L-material-release-form {
  max-width: 550px;
  width: 100%;

  .G-select-control {
    width: 100%;
  }
  .G-material-value-input-wrap,
  .G-numeric-format-filed {
    width: 50%;
  }
}
.L-material-release-form-filed {
  margin-top: 10px;
}
.L-mat-stock-balance-table,
.L-warehouse-release-prod-table {
  input {
    background: transparent;
  }
}

.L-account-box {
  width: 50%;
  border-bottom-left-radius: 4px;
  border: 1px solid #154632;
  border-top: none;
  border-right: none;
  padding: 5px 6px;
  strong {
    font-size: 13px;
    font-weight: 100;
  }
}
