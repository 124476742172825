.L-warehouse-entry-form {
  max-width: 1200px;
  width: 100%;
  .L-general-part-warehouse-entrance {
    width: 70%;
    display: flex;

    flex-wrap: wrap;
    align-items: flex-start;
    margin: -5px;

    .G-warehouse-entrance-wrap {
      width: 50%;

      padding: 5px;

      &:nth-child(1) {
        max-width: 200px;
        width: 100%;

        .radio-wrap {
          &:nth-child(2) {
            margin-left: 15px;
          }
        }
      }
      &:nth-child(2),
      &:nth-child(3) {
        max-width: 160px;
        width: 100%;
      }
      // &:last-child {
      //   width: 100%;
      // }
    }
  }
  .L-income-sign-flex {
    width: 100%;
    display: flex;
    align-items: flex-end;
    margin-top: 10px;
    .G-numeric-format-filed {
      width: 50%;
    }
    .G-checkbox-wrapper {
      margin-left: 10px;
    }
  }
}
.L-product-add-btn {
  max-width: 100px;
  width: 100%;
  margin-top: 15px;
}

.L-warehouseEntry-produce-form {
  max-width: 500px;
  width: 100%;
  .L-warehouseEntry-produce-filed {
    width: 100%;
    margin-top: 10px;
    &:nth-child(n + 3) {
      width: 50%;
    }
  }
}
.L-warehouseEntry-filter-block {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.L-warehouseEntry-filter-flex {
  display: flex;

  width: 95%;

  .G-warehouseEntry-filter-box {
    max-width: 250px;
    width: 100%;
    margin-left: 10px;
    &:nth-child(1) {
      max-width: 220px;
      width: 100%;
      margin-left: 0;
      .calendar-box {
        &:last-child {
          margin-left: 10px;
        }
      }
    }
    &:nth-child(3) {
      max-width: 120px;
      width: 100%;
    }
    .G-select-control {
      width: 100%;
    }
  }
}

.L-product-total {
  margin-top: 15px;
  display: flex;
  justify-content: flex-end;
  width: 68%;
  font-size: 12px;
  p {
    margin-left: 10px;
  }
}
.L-E-Invoicing-btn {
  max-width: 160px;
  width: 100%;
  margin-top: 20px;
}
