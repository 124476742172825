.L-calculation-sheet-modal {
  max-width: 1100px;
  width: 100%;
}
.L-calculation-sheet-action {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 20px;

  .G-select-control {
    max-width: 160px;
    width: 100%;
  }
  .G-cancel-print-btn-block {
    margin-top: 0;
  }
}
.calculation-total {
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
  flex-direction: column;
  .calculation-total-section {
    display: flex;
    margin-top: 10px;
    max-width: 509px;
    width: 100%;
    // &:nth-child(2) {
    //   max-width: 390px;
    //   width: 100%;
    //   background-color: red;
    // }
  }
  .calculation-total-label {
    width: 105px;
    font-size: 14px;
    color: black;
    font-weight: 100;
    display: block;
  }
  .calculation-total-value {
    font-size: 12px;
    max-width: 150px;
    width: 100%;
    text-align: right;
  }
  // .calculation-total-value {
  //   margin-left: 10px;
  //   max-width: 150px;
  //   width: 100%;
  // }
}
.L-calculation-sheet-table {
  .G-table-body-cell {
    &:nth-child(7),
    &:nth-child(8) {
      justify-content: end;
    }
  }
}

.L-calculation-sheet-table {
  .row-has-old-date {
    .G-table-body-cell {
      color: #84898d !important;
    }
  }
}

.L-calculation-print-modal {
  max-width: 450px;
  width: 100%;
  .L-calculation-dropdown-block {
    margin: 0 -10px;
  }
  .L-calculation-dropdown-filed {
    width: 50%;
    padding: 0 10px;
  }
  .G-add-btn {
    margin-top: 15px;
  }
}
