.G-flex {
  display: flex;
}

.G-flex-wrap {
  flex-wrap: wrap;
}

.G-flex-row {
  display: flex;
  flex-direction: row;
}

.G-flex-justify-start {
  display: flex;
  justify-content: flex-start;
}

.G-flex-justify-center {
  display: flex;
  justify-content: center;
}

.G-flex-justify-between {
  display: flex;
  justify-content: space-between;
}

.G-flex-justify-end {
  display: flex;
  justify-content: flex-end;
}

.G-flex-align-start {
  display: flex;
  align-items: flex-start;
}

.G-flex-align-center {
  display: flex;
  align-items: center;
}

.G-flex-align-end {
  display: flex;
  align-items: flex-end;
}

.G-column {
  display: flex;
  flex-direction: column;
}

.G-column-justify-start {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
}

.G-column-justify-center {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.G-column-justify-between {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.G-column-justify-end {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
}

.G-column-align-start {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.G-column-align-center {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.G-column-align-end {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
}

.G-flex-basis-auto {
  display: flex;
  flex-basis: auto;
}

.G-justify-between-align-center {
  display: flex;
  justify-content: space-between;
  align-items: center;
}



.G-justify-around-align-center {
  display: flex;
  justify-content: space-around;

}