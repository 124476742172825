.L-spending-money-action-block {
  .L-reporting-person-wrapper {
    max-width: 300px;
    width: 100%;
    margin-left: 15px;
  }
}

.L-spending-money-section {
  max-width: 1355px;
  width: 100%;

  .L-spending-money-section-content {
    width: 100%;

    .L-purpose-providing-money {
      margin-top: 15px;
    }

    .G-modal-btn-block {
      width: 100%;
      margin-top: 0;

      .G-modal-btn-box {
        &:last-child {
          max-width: 200px;
          width: 100%;
        }
      }
    }
  }
}

.L-spending-money-add-operations-flex {
  display: flex;
  justify-content: space-between;
  margin: 20px 0;

  .L-spending-money-btn {
    max-width: 200px;
    width: 100%;
  }
}

.G-time-partner-flex {
  .G-add-icon {
    margin-top: 25px;
    background: #fff;
  }
}

//start L-purpose-providing-money-block style

.L-purpose-providing-money-block {
  margin-top: 15px;

  .L-reporting-person {
    max-width: 300px;
    width: 100%;
  }

  .L-purpose-providing-money {
    .L-calendar {
      margin: 0 15px;
    }

    .L-docNum-block {
      max-width: 150px;
      width: 100%;
    }

    .L-money-basis-block {
      max-width: 300px;
      width: 100%;
    }
  }
}

//start L-account-money-received-block

$MarginLeft: 10px;

.L-account-money-received-block {
  margin: 20px 0;

  .L-account-money-received-content {
    margin-top: 20px;

    .L-received-and-money-spent-flow-flex {
      .L-received-and-money-spent-flow {
        margin-left: $MarginLeft;

        .L-date-amount-block {
          margin-bottom: 23px;
          .L-spending-money-date {
              span {
                font-size: 14px;
                font-weight: 100;
              }
            }
        }

        .L-filter-by-date,
        .L-filter-by-amount {
          margin-left: $MarginLeft;
        }

        .L-spending-money-date {
          span {
            &:first-child {
              margin-left: 0;
            }

            width: 118px;
            margin-left: 12px;
          }
        }

        .G-data-wrapper {
          margin: 5px;
        }

        .L-sub-amount {
          width: 118px;
          margin: 5px;
        }
      }

      .L-total {
        margin-left: $MarginLeft;
      }
    }
  }

  .G-account-input {
    max-width: 135px;
    width: 100%;
    margin-top: 28px;
  }
}

//start L-total-expenditure-block

.L-total-expenditure-block {
  .L-sub-deals {
    margin-left: $MarginLeft;
  }
}

//start L-spending-form-block

.L-spending-form-block {
  max-width: 500px;
  width: 100%;

  .L-spending-form-content {
    width: 100%;

    .L-spending-input {
      width: 100%;
      position: relative;
      margin-bottom: 15px;

      &:nth-child(3),
      &:nth-child(4) {
        max-width: 40%;
      }
    }

    .G-modal-btn-block {
      margin-top: 15px;
    }
  }
}

// start L-operation-form-block

.L-operation-form-block {
  max-width: 600px;
  width: 100%;

  .L-operation-left-panel {
    width: 40%;

    h3 {
      width: 100%;
      margin-top: 18px;
      font-size: 16px;
      font-weight: 200;
    }
  }

  .L-operation-right-panel {
    width: 60%;

    p {
      font-size: 16px;
      margin-top: 18px;
    }
  }

  .L-operation-select-box {
    width: 100%;
    margin-top: 10px;
  }

  .L-calendar {
    margin-top: 10px;
  }

  .L-operations-amount {
    width: 40%;
    margin-top: 15px;
  }

  .G-modal-btn-block {
    margin-top: 15px;
  }
}

.L-account-sending-money-table {
  .G-table-body-cell {
    &:nth-child(4),
      &:nth-child(5),
      &:nth-child(6),
    
      &:nth-child(7) {
        justify-content: flex-end;
      }
    }
    }

.L-spending-money-deals-table {
  .G-table-body-cell {
    &:nth-child(2) {
        overflow: hidden;
      }
        &:nth-child(4),
        &:nth-child(5) {
            justify-content: flex-end;
          }
        }
}
.L-purchase-act-operations-modal {
  max-width: 990px;
  width: 100%;
}
