.L-account-statement-table {
  .G-table-body-cell {
    &:nth-child(3),
    &:nth-child(4) {
      justify-content: flex-end;
    }
  }
}

.G-t-account-form-flex {
  .G-excel-print-btn-flex {
    display: flex;
    align-items: center;
    max-width: 290px;
    width: 100%;
  }
}
