.calendar-filtered-data-block {
    max-width: 600px;
    width: 100%;

    .filtered-data-box {
        margin-top: 5px;
        border-bottom: 1px solid black;
        strong {
            display: block;
            text-align: center;
        }
        h3 {
            text-align: center;
            font-size: 18px;
            font-weight: 500;
            color: rgb(15, 15, 15);
            margin: 10px 0;
        }
        p {
            font-size: 14px;
            margin-bottom: 2px;
        }
    }
   
}

.L-filter-area-block {
     .G-search-btn{
            margin: 12px auto 0;
    }
    .G-title-p {
        font-weight: bold;
    }
    .G-input-wrapper {
        margin-top: 10px;
    }
}

.calendar-filtered-data-content {
    .L-excel-icon {
        margin-left: 0;
    }
}
