@import '../../Assets/Scss/globalStylers.scss';

$marginBottom: 10px;
$padding: 0 10px;

.L-operations-form {
  max-width: 520px;
  width: 100%;

  .G-add-icon {
    margin-top: 13px;
  }

  .L-operations-form-content {
    width: 100%;

    .G-calendar-and-document-block {
      margin-bottom: $marginBottom;
    }

    .G-docNum-input {
      margin-left: 15px;
    }
  }

  .L-doctype-wrapper {
    max-width: 300px;
    width: 100%;
    margin-bottom: $marginBottom;
  }

  .G-currency-wrapper {
    margin-bottom: $marginBottom;
  }

  .L-debit-credit-block {
    margin-bottom: $marginBottom;

    .G-radio-button {
      &:nth-child(2) {
        margin-left: 10px !important;
      }
    }

    .L-available-amount {
      margin-left: 20px;
    }
  }

  .G-partner-wrapper {
    margin-bottom: $marginBottom;
  }

  .L-debit-credit-wrapper {
    max-width: 400px;
    width: 100%;
    margin-bottom: $marginBottom;
  }

  .G-amount-input {
    max-width: 50%;
    margin-bottom: $marginBottom;
  }

  .G-description-input {
    max-width: 100%;
    margin-bottom: $marginBottom;
  }
}

.L-operations-search-block {
  display: flex;
  align-items: flex-start;
  flex-wrap: wrap;

  .G-calendar-wrapper {
    max-width: 215px;
    width: 100%;
  }

  .G-docNum-input {
    max-width: 105px;
    width: 100%;
  }

  .G-docTypes-wrapper {
    max-width: 250px;
    width: 100%;
  }

  .L-account-box {
    max-width: 90px;
    width: 100%;
  }
  .G-currency-wrapper {
    max-width: 124px;
    width: 100%;
  }
  .L-amount-box {
    max-width: 150px;
    width: 100%;
  }

  .G-partner-wrapper {
    max-width: 250px;
    width: 100%;
  }

  .G-docNum-input,
  .G-docTypes-wrapper,
  .L-account-box,
  .L-amount-box,
  .G-partner-wrapper,
  .G-currency-wrapper {
    margin-left: 5px;
    margin-right: 5px;
  }
}

.L-operations-table {
  .G-table-body-cell {
    &:nth-child(2) {
      @include whiteSpaceNowrap;
    }
  }
  .G-table-body-cell {
    &:nth-child(6),
    &:nth-child(8) {
      justify-content: center;
    }
  }
  .G-table-body-cell {
    &:nth-child(10),
    &:nth-child(11),
    &:nth-child(12) {
      justify-content: flex-end;
    }
  }
}

.L-operations-amount-flex {
  margin: 10px 0 0 -15px;

  .G-numeric-format-filed {
    padding-left: 15px;
  }

  .G-exchangeRate-input {
    margin-top: 0;
    // padding-left: 15px;
  }
}

.L-operations-flex {
  display: flex;

  .G-currency-wrapper {
    margin-left: 15px;
  }

  .G-currency-wrapper {
    max-width: 130px;
  }
}

.L-search-and-export-btn {
  margin-top: 15px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  .G-search-btn {
    margin: 0 auto;
  }
}
