.top-arrow-comp {
    width: 28px;
    height: 28px;
    cursor: pointer;

    >img {
        display: flex;
        width: 100%;
        height: 100%;
    }
}