.L-material-values-menu-bar {
  width: 4%;
  height: 100vh;
  position: fixed;
  left: 0;
  top: 0;
  background: #154632;
  transition: 0.3s;
  z-index: 5;

  .L-menu-list {
    margin-top: 55px;
    width: 100%;
  }

  .L-menu-children {
    min-width: 300px;
    position: absolute;
    left: 100%;
    top: -1px;
    background: #103626;
    border: 1px solid #103626;
    border-radius: 0px 6px 0px 0px;
    display: none;

    .G-menu-title {
      font-size: 14px;
      color: white;
      padding: 5px 24px;
      white-space: nowrap;
    }

    > ul {
      max-height: calc(29 * 7px);
      overflow-y: auto;

      &::-webkit-scrollbar {
        width: 7px;
        height: 8px;
        background: #277053;
        border-radius: 100px;
      }

      &::-webkit-scrollbar-thumb {
        background-color: #858a95;
        width: 8px;
        -webkit-border-radius: 10px;
        border-radius: 10px;
        cursor: pointer;
      }
    }

    li {
      padding: 5px 24px;
      transition: 0.3s;
      cursor: pointer;
      white-space: nowrap;

      a {
        font-size: 14px;
        line-height: 18px;
        letter-spacing: 0.05em;
        color: white;
        transition: 0.3s;
      }

      &:hover {
        background: #3e8367;
      }

      &:hover {
        a {
          color: #ffffff;
        }
      }
    }
  }

  .L-menu-item {
    display: flex;
    align-items: center;
    transition: 0.3s;
    margin-top: 15px;
    position: relative;

    &:first-child {
      margin-top: 0;
    }

    > a {
      padding: 9px 5px;
      display: flex;
      width: 100%;
      justify-content: center;
    }

    &:hover {
      .L-menu-children {
        display: block;
      }
    }

    &::before {
      content: '';
      position: absolute;
      left: 0;
      top: 0;
      width: 9px;
      height: 100%;
      background: #154632;
      border-radius: 0px 10px 10px 0px;
      display: none;
      transform: 0.3s;
    }

    &:hover {
      background: #eee9;

      span {
        color: #000;
      }

      &:before {
        display: block;
      }
    }

    img {
      width: 30px;
    }

    a {
      span {
        margin-left: 20px;
        font-size: 15px;
        line-height: 23px;
        color: #ffffff;
        margin-top: 2px;
      }
    }
  }

  .logo-wrap {
    margin-top: 20px;
    .L-log-out {
      margin-left: 21px;
    }
  }
}

.L-menu-grandchildren {
  min-width: 100px;
  position: absolute;
  left: 100%;
  top: 50%;
  background: #103626;
  border: 1px solid #103626;
  border-radius: 0px 6px 0px 0px;
  display: none;
}

.L-item-child {
  &:hover {
    .L-menu-grandchildren {
      display: block;
    }
  }
}
