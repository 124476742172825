//start UsefulTools
//import SalaryIcon from '../../../Assets/homeIcons/leftArrow.png';
.useful-tools-block {
  max-width: 602px;
  width: 100%;
  margin: 0 auto;
}
.useful-tools-block-content {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}

.use-ful-tools-wrapper {
  > a {
    color: #545658;
    position: relative;
    line-height: 15px;
    font-size: 12px;
    transition: 0.3s;
    display: flex;
    align-items: center;

    img {
      max-width: 70px;
      width: 100%;
      height: 70px;
      background-repeat: no-repeat;
      background-size: cover;
      background-position: center;
      display: flex;
      padding-right: 10px;
    }
  }
}

.last-child {
  position: relative;

  .notification {
    position: absolute;
    top: -11px;
    left: 42px;
    padding: 5px 10px;
    cursor: pointer;
    border-radius: 50%;
    z-index: 999;
    .noti-img {
      width: 28px !important;
      height: 28px !important;
    }
  }
}

.badge {
  position: absolute;
  width: 20px;
  height: 20px;
  border: 2px solid rgb(199, 190, 190);
  border-radius: 50%;
  background-color: red;
  top: 5px;
  right: 4px;
  color: white;
  font-size: 13px;
  font-weight: bold;
  text-align: center;
}

.use-ful-tools-box {
  width: 100%;
  padding: 0 45px;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  align-items: flex-start;
}
.tool-item-box {
  width: 100%;
}
.tool-item-content {
  margin: 12px 0;

  h3 {
    position: relative;
    width: max-content;
    &::before {
      content: '';
      position: absolute;
      bottom: -4px;
      width: 100%;
      height: 2px;
      background-color: #897878;
    }
  }
}
.useful-tools-panel {
  width: 50%;
  &:nth-child(2) {
    padding-left: 30px;
  }
}
.img-p {
  > a {
    > img {
      max-width: 180px;
      width: 100%;
      height: 40px;
      background-repeat: no-repeat;
      background-size: cover;
      background-position: center;
      display: flex;
    }
  }
}

.important-tools {
  .tool-item-content {
    &:nth-child(2) {
      img {
        max-width: 130px;
        width: 100%;
        height: 63px;
      }
    }
    &:nth-child(3) {
      img {
        max-width: 126px;
        width: 100%;
        height: 26px;
      }
    }

    &:nth-child(5) {
      img {
        max-width: 230px;
        width: 100%;
        height: 50px;
      }
    }

    &:nth-child(4),
    &:nth-child(6),
    &:nth-child(7) {
      img {
        max-width: 240px;
        width: 100%;
        height: 50px;
      }
    }

    &:nth-child(8) {
      img {
        max-width: 140px;
        height: 35px;
      }
    }
    &:nth-child(9) {
      img {
        max-width: 90px;
        height: 30px;
      }
    }
    &:nth-child(10) {
      img {
        max-width: 98px;
        height: 60px;
      }
    }
  }
}

.basic-tools {
  .tool-item-content {
    &:nth-child(2) {
      img {
        height: 55px;
      }
    }
    &:nth-child(3) {
      img {
        height: 55px;
      }
    }
    &:nth-child(4) {
      img {
        max-width: 75px;
        width: 100%;
        height: 54px;
      }
    }
    &:nth-child(5) {
      img {
        max-width: 75px;
        width: 100%;
        height: 64px;
      }
    }
  }
  .tool-item-content {
    &:nth-child(6) {
      img {
        max-width: 80px;
        width: 100%;
        height: 60px;
      }
    }
  }
}

.information-sites {
  margin-top: 30px;
  .tool-item-content {
    &:nth-child(2) {
      img {
        max-width: 70px;
        height: 45px;
      }
    }
    &:nth-child(3) {
      img {
        max-width: 70px;
        height: 58px;
      }
    }

    &:nth-child(4) {
      img {
        max-width: 73px;
        height: 53px;
      }
    }
    &:nth-child(5) {
      img {
        max-width: 94px;
      }
    }
    &:nth-child(6) {
      img {
        max-width: 60px;
        height: 60px;
      }
    }
  }
}

.use-ful-tools-wrapper {
  position: relative;

  .hover-block {
    position: absolute;
    top: 0;
    left: -284px;
    width: 250px;
    height: 70px;
    background-color: white;
    transition: right 0.3s ease-in-out;
    z-index: 999;
    display: none;
    color: black;
    border-radius: 4px;
    background-color: #fff;
    box-shadow: 0 19px 38px rgba(0, 0, 0, 0.3), 0 15px 12px rgba(0, 0, 0, 0.22);
    padding: 10px;
  }
  &:hover {
    .hover-block {
      display: block;
      right: 0;
    }
  }
}
// .icon {
//   &:hover {
//     .hover-block {
//       display: block;
//       right: 0;
//     }
//   }
// }
.important-tools {
  .use-ful-tools-wrapper {
    position: relative;
    .hover-block {
      position: absolute;
      top: 0;
      left: unset;
      right: -270px;
    }
  }
}
