.G-user-data-block {
  max-width: 500px;
  width: 100%;
  border: 1px solid #b7b9bb;
  padding: 15px 10px;
  border-radius: 4px;
  box-shadow: 0 19px 38px rgba(0, 0, 0, 0.3), 0 15px 12px rgba(0, 0, 0, 0.22);
  margin-top: 40px;
  margin-left: 40px;
  .G-help-btn {
    top: 35px;
  }
}

.user-data-block-solid {
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 2px solid #b8b8b8;
  padding: 10px 0;

  &:last-child {
    border-bottom: none;
  }
}

.user-img-block {
  width: 100px;
  height: 110px;

  img {
    display: flex;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
}

.user-info {
  display: flex;
  flex-wrap: wrap;

  span {
    display: block;
    margin-left: 5px;
    font-size: 14px;
  }
}

.pencil-box {
  display: block;
  width: 20px;
  height: 20px;
  cursor: pointer;

  img {
    width: 100%;
  }
}
.TopArrow {
  background-color: #0004;
}
.user-img-block {
  max-width: 120px;
  width: 100%;
  position: relative;
  .delete-icon-box {
    position: absolute;
    right: -20px;
    bottom: 0;
    cursor: pointer;
    img {
      width: 30px;
      height: 30px;
    }
  }
}

.L-email-mess-modal {
  max-width: 500px;
  width: 100%;
  left: 50% !important;
  top: 14% !important;
  transform: translate(-50%, 14%);
  z-index: 99999999999;
  p {
    word-wrap: break-word;
    font-size: 14px;
    word-spacing: 2px;
  }
  .email-btn-wrap {
    margin-top: 20px;
    display: flex;

    max-width: 120px;
    width: 100%;
  }
}
.from-user-data {
  .L-organization-action-btn {
    display: none;
  }
  .L-reporting-period-title {
    top: -13px;
  }
}

.L-user-data-header {
  h3 {
    font-weight: 100;
  }
}
