.G-user-info-block {
  .G-user-flex {
    display: flex;
    align-items: center;

    .G-user-name-surname {
      display: block;
      font-size: 14px;
      line-height: 16px;
      color: #000000;
      margin-left: 6px;
    }

    .G-user-default-img {
      cursor: pointer;
display: flex;

      img {
        width: 36px;
        height: 36px;
        border-radius: 50%;
          background-size: cover;
          background-position: center;
          background-repeat: no-repeat;
      }
    }
  }
}

.G-user-open-modal {
  position: relative;
  z-index: 9;
}

// G user open modal style
.G-user-setting-list {
  width: 200px;
  position: absolute;
  top: 2px;
  right: -20px;
  background: #0009;
  border-radius: 6px;

  &::before {
    content: "";
    position: absolute;
    top: -9px;
    left: 50%;
    width: 17px;
    height: 17px;
    clip-path: polygon(0 0, 100% 0, 100% 100%);
    transform: rotate(-45deg);
    background: #000000;
  }

  ul {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;

    li {
      width: 100%;
      transition: 0.3s;
      display: flex;
      padding: 16px 17px 16px 13px;
      align-items: center;
      cursor: pointer;

      &:first-child {
        margin-top: 20px;
      }

      li+li {
        margin-top: 5px;
      }

      &:last-child {
        margin-top: 10px;
      }

      a {
        display: flex;
        align-items: center;
        transition: 0.3s;

        span {
          font-size: 12px;
          line-height: 14px;
          color: #FFFF;
          padding-left: 12px;
        }
      }

      &:hover {
        span {
          color: #ffffff;
        }
      }

      .G-user-setting-icon {
        >svg {
          width: 14px !important;
          height: 14px;

          path {
            fill: #ffffff;
          }

        }
      }



      &:hover {
        background: black;
        border-radius: 0px 0px 6px 6px;
      }

      span {
        font-size: 12px;
        line-height: 14px;
        color: #FFFF;
        padding-left: 12px;
      }
    }
  }
}