.L-bank-paymentList-actions-block {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  .L-bank-paymentList-dropdown-flex,
  .L-bank-paymentList-btn-flex {
    max-width: 800px;
    width: 100%;
  }
}

.L-bank-paymentList-dropdown-flex {
  display: flex;
  align-items: center;
  .G-select-control {
    max-width: 230px;
    width: 100%;

    &:last-child {
      margin-left: 10px;
    }
  }
}
.L-bank-paymentList-btn-flex {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  .G-paymentList-btn {
    max-width: 90px;
    width: 100%;
    &:first-child {
      margin-right: 10px;
    }
  }
}

.L-new-paymentList-form {
  max-width: 350px;
  width: 100%;
  .L-new-paymentList-input-flex {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    .G-numeric-format-filed {
      max-width: 140px;
      width: 100%;
      margin-top: 10px;
    }
  }
  .G-checkbox-wrapper,
  .G-s-input-wrap,
  .bank-name {
    margin-top: 10px;
    font-size: 12px;
  }

  .G-s-input-wrap {
    max-width: 100px;
    width: 100%;
  }
}

.L-new-paymentList-form-btn,
.L-payment-confirm-remove-btn {
  margin-top: 15px;
}
.L-paymentList-total {
  width: 100%;
  display: flex;
  justify-content: flex-end;
  margin-top: 12px;

  p {
    font-size: 14px;
    max-width: 276px;
    width: 100%;
    &:first-child {
      max-width: 159px;
      width: 100%;
    }
  }
}
.L-advance-payment-flex {
  display: flex;
  align-items: flex-start;
  .G-s-input-wrap {
    margin-left: 15px;
  }
}

.L-payment-lists-table {
  .G-table-body-cell {
    .G-numeric-format-filed {
      input {
        background: transparent;
        border: none;
      }
    }
    &:nth-child(7) {
      justify-content: end;
    }
  }
}
