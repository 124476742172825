.G-alert-dialog-modal {
    max-width: 400px;
    width: 100%;
    padding: 15px;
    top: 0;
   // transform: translate(-50%, 0);
    z-index: 99999999999;
    .close {
    
            right: 3px;
            top: 5px;
        }
}

.G-alert-dialog {
    padding: 10px 15px;

    >p {
        text-align: center;
        display: block;
        font-weight: 200;
        line-height: 16px;
        font-size: 14px;
    }
}