.G-T-account-wrapper {
  .G-t-account-balance {
    display: flex;
    margin: 15px 0;
    h4{
      font-weight: 100;
    }
    > h4 {
      font-size: 14px;
      margin-left: 15px;
    
    }
    > span {
      display: block;
      font-size: 15px;
      margin-left: 15px;
    }
    p {
      margin: 0 10px;
    }
  }
  .G-t-account-initial-balance {
    h4 {
      &:first-child {
        margin-left: 0;
      }
    }
  }
  .G-t-account-final-balance {
    p {
      margin-left: 0;
    }
  }
}
