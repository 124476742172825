.report-dropdowns {
  display: flex;
  margin-top: 20px;
  .css-1s2u09g-control {
    background-color: white !important;
  }
  .css-qc6sy-singleValue {
    color: #000;
  }
  .css-1pahdxg-control {
    background-color: white;
    border-color: white;
    &:hover {
      background-color: white;
    }
  }
}

.filter-date-block {
  max-width: 800px;
  width: 100%;

  display: flex;
  align-items: flex-start;
  margin-top: 30px;
  .button-wrap {
    button {
      background: none;
      border: 1px solid #29403c;

      p {
        color: #000;
      }
    }
    &:nth-child(2) {
      margin: 0 5px;
    }
  }
  .active_btn {
    button {
      border: 1px solid #3f8f5b;
      background: #3f8f5b;
      box-shadow: inset 0px 4px 4px rgba(0, 0, 0, 0.25);
      p {
        color: white;
      }
    }
  }
}

.report-date-operations {
  width: 100%;
  display: flex;
}

.budget-summary-filter-flex {
  display: flex;
}
.filter-and-chose-wrap {
  max-width: 600px;
  width: 100%;
  margin-left: 30px;
  .date-interval-block {
    .date-title {
      text-align: center;
    }
  }
}

.chart-wrap {
  canvas {
    width: 500px !important;
    height: 250px !important;
  }
}
.radio-area {
  display: flex;
  justify-content: center;
  margin-left: 20px;
  .radio-btn-block {
    &:nth-child(2) {
      margin: 0 5px;
    }
  }
}
