.L-init-balance-form-modal {
  max-width: 470px;
  width: 100%;

  .G-account-wrapper,
  .L-initial-balances-wrap,
  .G-exchange-input,
  .G-currency-wrapper {
    margin-top: 10px;
  }

  .G-account-wrapper {
    max-width: 100%;
  }
}

.L-initial-balance-account-wrapper {
  max-width: 400px;
  margin: 0 auto;
}

.L-initial-balance-table {
  .G-table-body-cell {
    .G-account-is-active {
      text-align: start;
    }
    .partnerAccText {
      overflow: hidden;
    }
    &:nth-child(5),
    &:nth-child(6),
    &:nth-child(7),
    &:nth-child(8) {
      justify-content: flex-end;
    }

    &:nth-child(4) {
      justify-content: center;
    }
  }
}

.L-initial-balances-amount-wrap {
  position: relative;
  margin: 5px -10px;

  .L-balances-amount-box {
    width: 50%;
    padding: 5px 10px;
  }
}

.L-partner-account-balance {
  max-width: 1200px;
  width: 100%;
  .G-table-block {
    margin-top: 25px;
    .G-table-body-cell {
        &:nth-child(2), 
         &:nth-child(8) {
       overflow: hidden;
      }
      &:nth-child(3) {
        justify-content: center;
      }
      &:nth-child(4),
      &:nth-child(5),
      &:nth-child(6),
      &:nth-child(7) {
        justify-content: flex-end;
      }
    }
  }
}
