.L-summary-data-block {
  width: 60%;
  .G-add-btn {
    max-width: 80px;
  }
}

.G-Summary-calendar-wrap {
  .calendar-box {
    &:last-child {
      margin-left: 10px;
    }
  }
}
